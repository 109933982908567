import Voltxt from "Voltxt";
//import {useEffect} from "react";
import {Outlet} from 'react-router-dom';
//import ControlSidebar from 'modules/main/control-sidebar/ControlSidebar';
import Header from 'modules/main/header/Header';
//import MenuSidebar from 'modules/main/menu-sidebar/MenuSidebar';
import Footer from 'modules/main/footer/Footer';
//import {removeBodyClass, addBodyClass} from "utils/helpers";

const Main = () => {

    console.log('Main.tsx', Voltxt.instance.state);



    console.log('render main')

    /*useEffect(() => {

        addBodyClass('sidebar-mini');
        removeBodyClass('sidebar-collapse');

    }, []) // empty array is required*/

    return <div className="wrapper bg-body">
        <Header/>

        {/*<MenuSidebar/>
                NO LONGER IN USE
        */}

        <div className="bg-body">
            <div className="pt-3"/>
            <section className="content">
                <Outlet/>
            </section>
        </div>
        <Footer/>
        {/*<ControlSidebar/>
                NO LONGER IN USE
        */}
    </div>;
};

export default Main;
