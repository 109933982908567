import Voltxt from "Voltxt";
import FlaggedEntitiesListItem from "./FlaggedEntitiesListItem";

const FlaggedEntities = () => {

    const AllUsers = Voltxt.instance.state.flagged_entities;

    return <div className="card">
        <div className="card-header">
            <h3 className="card-title">Flagged Entities</h3>
            <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse"
                        title="Collapse">
                    <i className="fas fa-minus"></i>
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                    <i className="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div className="card-body p-0">
            <table className="table table-striped projects">
                <thead>
                <tr>
                    <th>
                        Report ID
                    </th>
                    <th>
                        Reporter
                    </th>
                    <th>
                        Reported<br/><small>(bad actor!?)</small>
                    </th>
                    <th>
                        User Is Disabled
                    </th>
                    <th>
                        Reported Entity
                    </th>
                    <th>
                        Entity is Deleted
                    </th>
                    <th>
                        Entity JSON
                    </th>
                    <th>
                        Entity Table JSON
                    </th>
                    <th>
                        Date Created:
                    </th>
                    <th>
                        Report Message
                    </th>
                    <th>
                        Report Image
                    </th>
                    <th>
                        Response Message
                    </th>
                    <th>
                        Responded By
                    </th>
                </tr>
                </thead>
                <tbody>
                {AllUsers?.map((flagged_entities, key) =>
                    <FlaggedEntitiesListItem key={key} flagged_entities={flagged_entities}/>)}
                </tbody>
            </table>
        </div>

    </div>
}


export default FlaggedEntities