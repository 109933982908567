import {PropsWithChildren} from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from "classnames";
import getStyles from "api/hoc/getStyles";


interface iPopupProperties {
    open?: boolean;
    handleClose: () => any;
    minWidth?: string;
    maxWidth?: string;

}

// @link https://stackoverflow.com/questions/58399637/include-modal-functionality-in-react-higher-order-component
export default function Popup({
                                  open = true,
                                  handleClose,
                                  children,
                                  maxWidth = 'auto',
                              }: PropsWithChildren<iPopupProperties>) {

    if (false === open) {

        return null;

    }

    const styles = getStyles()

    return <>
        <div className={classNames(styles.modal, styles.fade, styles.show, styles.dBlock)}
             style={{backgroundColor: "rgba(0,0,0,0.8)"}}
             id="exampleModalCenter"
             tabIndex={-1} aria-labelledby="exampleModalCenterTitle"
             aria-modal="true" role="dialog">
            <div style={{maxWidth: maxWidth}} className={classNames(
                styles.modalDialog, styles.modalDialogCentered,
            )}>
                <div
                    style={{overflowY: "scroll"}}
                    className={classNames(
                        styles.modalContent, styles.bgTransparent, styles.modalDialogCentered,
                        styles.borderTransparent
                    )}
                >
                    <OutsideClickHandler onOutsideClick={handleClose}>
                        {children}
                    </OutsideClickHandler>
                </div>
            </div>

        </div>
    </>

}

