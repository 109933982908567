
export interface WebsocketState {
    websocketData: Array<any>,
    websocketEvents: Array<any>,
    websocketMounted: boolean,
}

export const initialWebsocketState: WebsocketState = {
    websocketData: [],
    websocketEvents: [],
    websocketMounted: false,
};


