import Voltxt from "Voltxt";

export interface ChatState {
    chat: string;
}

export const initialChatState: ChatState = {
    chat: ''
}

export const setChat = (chat: string) => Voltxt.instance.setState({
    chat: chat
})