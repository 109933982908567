import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import Voltxt from "Voltxt";
import Post from './Post';

const ActivityTab = ({isActive}: { isActive: boolean }) => {
    const whoami = getCurrentLoggedInUser();
    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            {Voltxt.instance.state.posts
                ?.filter((post) => post.post_author === whoami?.user_id)
                ?.map((post, index) => <Post key={index} post={post}/>)}
        </div>
    );
};

export default ActivityTab;
