import defaultAvatar from "assets/img/defaultAvatar.svg";
import isLocal from "variables/isLocal";
import Chat_Messages from "../../api/rest/Chat_Messages";
import getCurrentLoggedInUser from "../../api/hoc/getCurrentLoggedInUser";
import Voltxt from "Voltxt";
import {setChat} from "state/chat";
import {convertUTCToLocal} from "api/hoc/time";
import {WithRouter} from "api/hoc/passPropertiesAndRender";
import {useState} from "react";
import Popup from "../Popup/Popup";
import Chat_Groups from "../../api/rest/Chat_Groups";
import {Link} from "react-router-dom";


export default function Chat(props: WithRouter | {}) {

    const chatGroupId = 'params' in props ? props?.params.groupId : null;

    const {chat, chat_messages, users, chat_groups, chat_group_members} = Voltxt.instance.state;

    const whoami = getCurrentLoggedInUser()

    const [showModal, setShowModal] = useState(false);

    const [showMemberModal, setShowMemberModal] = useState(false);

    const [chatGroup, setChatGroup] = useState('');

    const handleNewMessageClick = () => setShowModal(!showModal);

    const handleNewMemberClick = () => setShowMemberModal(!showMemberModal);

    const newPost = () => Chat_Messages.Post({
        chat_message: chat,
        chat_message_from_user: whoami?.user_id,
        chat_private_group: chatGroupId
    })
    const newGroup = () => Chat_Groups.Post({
        chat_group_created_by: whoami?.user_id,
        chat_group_name: chatGroup
    })


    return <>
        <div className="card direct-chat direct-chat-primary"
             style={{position: "relative", left: "0px", top: "0px"}}>
            <div className="card-header ui-sortable-handle" style={{cursor: "move"}}>
                <h3 className="card-title">{chatGroupId ? 'Direct' : 'Global'} Chat</h3>
                {chatGroupId &&
                    <button className="btn btn-primary" onClick={handleNewMemberClick}>Add Members</button>
                }
                {}
                <div className="card-tools">
                    {isLocal && <span title="3 New Messages" className="badge badge-primary">
                            3
                        </span>}
                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                    </button>
                    {isLocal && <>
                        <button type="button" className="btn btn-tool" title="Contacts" data-widget="chat-pane-toggle">
                            <i className="fas fa-comments"></i>
                        </button>
                        <button
                            type="button"
                            className="btn btn-tool"
                            title="New direct message"
                            onClick={handleNewMessageClick}
                        >
                            <i className="fas fa-user-plus"></i>
                        </button>
                    </>}
                    <button type="button" className="btn btn-tool" data-card-widget="remove">
                        <i className="fas fa-times"></i>
                    </button>
                </div>


            </div>


            <div className="card-body">

                <div className="direct-chat-messages">
                    <ul className="contacts-list">
                        {chat_messages?.filter(message => message.chat_private_group === chatGroupId).map((chat) => {

                            const chatFromUser = users?.find(user => user.user_id === chat.chat_message_from_user);

                            console.log('message', chat)

                            return <li>
                                    <Link to={'/profile/' + chatFromUser?.user_id} style={{cursor: 'pointer'}}>
                                        <img className="contacts-list-img"
                                             src={chatFromUser?.user_profile_pic ?? defaultAvatar} alt="User Avatar"/>
                                    </Link>
                                    <div className="contacts-list-info">
                                        <div className="contacts-list-name">
                                            <Link to={'/profile/' + chatFromUser?.user_id} style={{cursor: 'pointer'}}>
                                                {chatFromUser?.user_username ?? 'REMOVED'}
                                            </Link>
                                            <small
                                                className="contacts-list-date float-right">{convertUTCToLocal(chat.chat_message_datetime)}</small>
                                        </div>
                                        <span className="contacts-list-msg">{chat.chat_message}</span>
                                    </div>
                            </li>
                        })}
                    </ul>


                </div>


                <div className="direct-chat-contacts">
                    <ul className="contacts-list">


                        {chat_groups?.map((group) => {

                            const chatOwner = users?.find(user => user.user_id === group.chat_group_created_by)

                            let members = chat_group_members
                                ?.filter(users => users.chat_group_id === group.chat_group_id)
                                ?.map(groupMember => {
                                    return users?.find(user => user.user_id === groupMember.chat_group_member)
                                }) ?? []

                            if (chatOwner) {

                                members.push(chatOwner)

                            }

                            return <li>
                                <Link to={`/chat/${group.chat_group_id}`}>
                                    {members?.map((user) => user &&
                                        <Link to={`/profile/${user.user_id}`} style={{cursor: 'pointer'}}>
                                            <img className="contacts-list-img"
                                                 src={user.user_profile_pic ?? defaultAvatar}
                                                 alt={"message user"}/>
                                        </Link>
                                    )}
                                    <div className="contacts-list-info">
                                        <span className="contacts-list-name">
                                        {group.chat_group_name}
                                            <small className="contacts-list-date float-right">1/4/2015</small>
                                        </span>
                                        <span className="contacts-list-msg">
                                            Created by: <Link to={`/profile/${chatOwner?.user_id}`}
                                                              style={{cursor: 'pointer'}}
                                                              className="btn btn-primary btn-sm  mr-3">
                                                    {chatOwner?.user_username}
                                                </Link>
                                            {members.length > 1 && '; Members: ' + (members?.map(user => user &&
                                                <Link to={`/profile/${user.user_id}`}
                                                      style={{cursor: 'pointer'}}
                                                      className="btn btn-primary btn-sm  mr-3">
                                                    {user.user_username}
                                                </Link>))}
                                        </span>
                                    </div>
                                </Link>
                            </li>;

                        })}

                    </ul>

                </div>


            </div>

            <div className="card-footer">
                <form action="#" method="post">
                    <div className="input-group">
                        <input onChange={(e) => setChat(e.target.value)} type="text" name="message"
                               placeholder="Type Message ..." className="form-control"/>
                        <div className="input-group-append">
                            <button type="button" className="btn btn-primary" onClick={newPost}>Send
                            </button>
                        </div>
                    </div>
                </form>
            </div>


        </div>

        <Popup open={showModal} handleClose={handleNewMessageClick} maxWidth={'700px'}>
            <div className="card card-info">
                <div className="card-header">
                    <h3 className="card-title">Add Group</h3>
                </div>

                <div className="card-body">
                    <input onChange={(e) => setChatGroup(e.target.value)} className="form-control"
                           id="inputEmail3"
                           placeholder="Group Name"/>
                </div>

                <div className="card-footer">
                    <button className="btn btn-info" onClick={newGroup}>Submit</button>
                    <button className="btn btn-default float-right"
                            onClick={handleNewMessageClick}>Cancel
                    </button>
                </div>

            </div>
        </Popup>

        <Popup open={showMemberModal} handleClose={handleNewMemberClick}>
            <div className="card card-info">
                <div className="card-header">
                    <h3 className="card-title">Add Members</h3>
                </div>

                <div className="card-body">
                    <input onChange={(e) => setChatGroup(e.target.value)} className="form-control"
                           id="inputEmail3"
                           placeholder="Group Member Name"/>
                </div>

                <div className="card-footer">
                    <button className="btn btn-info" onClick={newGroup}>Submit</button>
                    <button className="btn btn-default float-right"
                            onClick={handleNewMemberClick}>Cancel
                    </button>
                </div>

            </div>
        </Popup>

    </>


}