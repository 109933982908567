import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import Users from "api/rest/Users";
import {useEffect, useState} from "react";
import {C6, users, projects} from "api/rest/C6";
import Projects from "api/rest/Projects"
import Voltxt from "Voltxt";
import ProjectListItem from "./ProjectListItem";
import UserListItem from "./UserListItem";

export default function Search() {

    const {search} = Voltxt.instance.state

    const setSearch = (search: string) => Voltxt.instance.setState({
        search: search
    })

    const [searchOption, setSearchOption] = useState('projectName'); // Default option

    const AllUsers = Voltxt.instance.state.users;

    const AllProjects = Voltxt.instance.state.projects;

    useEffect(() => {

        try {

            Users.Get({
                [C6.SELECT]: [
                    users.USER_ID,
                    users.USER_USERNAME,
                    users.USER_COVER_PHOTO,
                    users.USER_PROFILE_PIC,
                    users.USER_IS_CALLER,
                    users.USER_IS_MODERATOR,
                    users.USER_IS_ADMINISTRATOR,
                ],
                [C6.WHERE]: {
                    [users.USER_USERNAME]: [C6.LIKE, '%' + search + '%']
                },
            });

            if (searchOption === 'projectName') {

                Projects.Get({
                    [C6.SELECT]: [
                        projects.PROJECT_ID,
                        projects.PROJECT_NAME,
                        projects.PROJECT_DESCRIPTION,
                    ],
                    [C6.WHERE]: {
                        [projects.PROJECT_NAME]: [C6.LIKE, '%' + search + '%'],
                    },
                });
            } else if (searchOption === 'projectDescription') {
                Projects.Get({
                    [C6.SELECT]: [
                        projects.PROJECT_ID,
                        projects.PROJECT_NAME,
                        projects.PROJECT_DESCRIPTION,
                    ],
                    [C6.WHERE]: {
                        [projects.PROJECT_DESCRIPTION]: [C6.LIKE, '%' + search + '%'],
                    },
                });
            }

            // setProjectResults(projectResponse.data);

        } catch (error) {

            console.error('Error fetching search results:', error);

        }

    }, [search, searchOption]);

    const handleSearchOptionChange = (option) => {
        setSearchOption(option);
        setSearch(''); // Clear search when switching options
    };

    const whoami = getCurrentLoggedInUser();

    return <div className="container-fluid" data-select2-id="16">
        <h2 className="text-center display-4">Enhanced Search</h2>
        <form data-select2-id="15">
            <div className="row" data-select2-id="14">
                <div className="col-md-10 offset-md-1" data-select2-id="13">
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                            <input
                                type="search"
                                className="form-control form-control-lg"
                                placeholder="Type your keywords here"
                                value={search}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-lg btn-default">
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div className="form-group col-sm-4 d-inline-flex">
            <h3 className="w-50">Search users by:</h3>
            <select
                className="form-control"
                value={searchOption}
                onChange={(event) => handleSearchOptionChange(event.target.value)}
            >
                <option value="projectName">Username</option>
                <option value="projectDescription">Callers</option>
                <option value="projectDescription">Moderators</option>
                <option value="projectDescription">Administrators</option>
            </select>
        </div>

        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Users</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse"
                            title="Collapse">
                        <i className="fas fa-minus"></i>
                    </button>
                    <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped projects">
                    <thead>
                    <tr>
                        <th style={{width: "20%"}}>
                            Username
                        </th>
                        {(() => {
                            if (!whoami?.user_is_administrator) {
                                return
                            }
                            return <>
                                <th style={{width: "20%"}}>
                                    Disabled
                                </th>
                                <th style={{width: "20%"}}>
                                    Caller
                                </th>
                                <th style={{width: "20%"}}>
                                    Moderator
                                </th>
                                <th style={{width: "20%"}}>
                                    Administrator
                                </th>
                            </>

                        })()}
                        <th>
                            Projects
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {AllUsers?.map((user, key) => <UserListItem key={key} user={user}/>)}
                    </tbody>
                </table>
            </div>

        </div>

        <div className="form-group col-sm-4 d-inline-flex">
            <h3 className="w-50">Search projects by:</h3>
            <select
                className="form-control"
                value={searchOption}
                onChange={(event) => handleSearchOptionChange(event.target.value)}
            >
                <option value="projectName">Project Name</option>
                <option value="projectDescription">Project Description</option>
            </select>
        </div>

        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Projects</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse"
                            title="Collapse">
                        <i className="fas fa-minus"></i>
                    </button>
                    <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped projects">
                    <thead>
                    <tr>

                        <th style={{width: "20%"}}>
                            Project Name
                        </th>
                        <th style={{width: "30%"}}>
                            Team Members
                        </th>
                        <th>
                            Project Description
                        </th>
                        <th className="text-center">
                            Project Progress
                        </th>
                        <th style={{width: "20%"}}>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {AllProjects?.map((project) => <ProjectListItem project={project}/>)}
                    </tbody>
                </table>
            </div>

        </div>
    </div>
}