import {globalNavigate} from "@carbonorm/carbonreact";
import getStyles from "api/hoc/getStyles";
import Users from "api/rest/Users";
import Voltxt from "Voltxt";
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';


import {Form, InputGroup} from 'react-bootstrap';
import Popup from "components/Popup/Popup";
import {iUsers} from "api/rest/C6";

const Login = () => {

    const styles = getStyles();

    const [isAuthLoading, setAuthLoading] = useState(false);

    const navigate = useNavigate();

    const login = async (email: string, password: string) => {

        setAuthLoading(true);

        const loginReturn = await Users.Post({
            user_username: email,
            user_password: password
        })

        console.log('login response', loginReturn);

        if (loginReturn?.data) {

            Voltxt.instance.setState({
                user_id: loginReturn.data.rest.user_id
            })

            Voltxt.instance.updateRestfulObjectArrays<iUsers>({
                dataOrCallback: [
                    loginReturn.data.rest
                ],
               stateKey:  'users',
                uniqueObjectId: 'user_id'
            });

            return {};

        }
        setAuthLoading(false);

        navigate('/');

    }

    const {handleChange, values, handleSubmit, touched, errors} = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .min(8, 'Must be at least 8 characters')
                .max(20, 'Must be less  than 20 characters')
                .required('Username is required'),
            password: Yup.string()
                .min(5, 'Must be 5 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Required'),
        }),
        onSubmit: (values) => {
            login(values.username, values.password);
        },
    });


    return (<Popup handleClose={() => {

            globalNavigate('/')

        }}>
            <div className={styles.loginBox}>
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <Link to="/" className="h1">
                            <b>Affinity</b>
                            <span>Voltex</span>
                        </Link>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign In</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        id="username"
                                        name="username"
                                        type="username"
                                        placeholder="Username"
                                        onChange={handleChange}
                                        value={values.username}
                                        isValid={touched.username && !errors.username}
                                        isInvalid={touched.username && !!errors.username}
                                    />
                                    {touched.username && errors.username ? (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.username}
                                        </Form.Control.Feedback>
                                    ) : (
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <i className="fas fa-envelope"/>
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    )}
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        value={values.password}
                                        isValid={touched.password && !errors.password}
                                        isInvalid={touched.password && !!errors.password}
                                    />
                                    {touched.password && errors.password ? (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    ) : (
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <i className="fas fa-lock"/>
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    )}
                                </InputGroup>
                            </div>

                            <div className="row">
                                <div className="col-8">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"/>
                                            <label
                                                className="form-check-label">Remember me</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary"
                                            disabled={isAuthLoading}>
                                        Sign in
                                    </button>
                                </div>

                            </div>
                        </form>
                        <div className="social-auth-links text-center mt-2 mb-3">
                            {/*<button type="submit" className="btn btn-primary" onClick={() => {

                            }}>
                                <i className="fab fa-facebook mr-2"/>
                                Login with Facebook
                            </button>
                            <br/>
                            <button type="submit" className="btn btn-danger">
                                <i className="fab fa-google mr-2"/>
                                Login with Google
                            </button>*/}
                            <button className="btn btn-block" style={{backgroundColor:"#000000", color:"#fff"}} onClick={() => {
                                window.location.replace('/bootstrap/twitter/login/')
                            }}>
                                <i className="fab fa-x-twitter mr-2" />
                            </button>
                            <button className="btn btn-block" style={{backgroundColor:"#5865F2"}} onClick={() => {
                                window.location.replace('/bootstrap/discord/login/')
                            }}>
                                <i className="fab fa-discord mr-2" aria-label={'discord'}/>
                            </button>
                        </div>
                        <p className="mb-1">
                            <Link to="/forgot-password">
                                Forgot password
                            </Link>
                        </p>
                        <p className="mb-0">
                            <Link to="/register" className="text-center">
                                Register a new membership
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default Login;