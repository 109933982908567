import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import Users from "api/rest/Users";
import {useState} from "react";
import {Link} from 'react-router-dom';

const SettingsTab = ({isActive}: { isActive: boolean }) => {

    const whoami = getCurrentLoggedInUser();

    const [user, setUser] = useState(whoami);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        Users.Put({
            user_id: whoami?.user_id,
            ...user,
        })
    }

    return <div className={`tab-pane ${isActive ? 'active' : ''}`}>
        <form className="form-horizontal">
            <div className="form-group row">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                    First Name
                </label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        placeholder={user?.user_first_name ?? "First Name"}
                        onChange={e => setUser({...user, user_first_name: e.target.value})}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">
                    Last Name
                </label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        placeholder={user?.user_last_name ?? "Last Name"}
                        onChange={e => setUser({...user, user_last_name: e.target.value})}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
                    Email
                </label>
                <div className="col-sm-10">
                    <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder={user?.user_email ?? "Email"}
                        onChange={e => setUser({...user, user_email: e.target.value})}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputSkills" className="col-sm-2 col-form-label">
                    Skills
                </label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        className="form-control"
                        id="inputSkills"
                        placeholder={user?.user_skills ?? "Skill One, Skill Two, Skill Three, ..."}
                        onChange={e => setUser({...user, user_skills: e.target.value})}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEducation" className="col-sm-2 col-form-label">
                    Education
                </label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        className="form-control"
                        id="inputEducation"
                        placeholder={user?.user_education ?? "Education"}
                        onChange={e => setUser({...user, user_education: e.target.value})}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputExperience" className="col-sm-2 col-form-label">
                    Notes
                </label>
                <div className="col-sm-10">
            <textarea
                className="form-control"
                id="inputExperience"
                defaultValue=""
                placeholder={user?.user_notes ?? "Notes"}
                onChange={e => setUser({...user, user_notes: e.target.value})}
            />
                </div>
            </div>
            <div className="form-group row">
                <div className="offset-sm-2 col-sm-10">
                    <div className="icheck-primary">
                        <input
                            type="checkbox"
                            id="agreeTerms"
                            name="terms"
                            defaultValue="agree"
                            onChange={e => setTermsAccepted(e.target.checked)}
                        />
                        <label htmlFor="agreeTerms">
                            <span>I agree to the </span>
                            <Link to="/">terms and condition</Link>
                        </label>
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <div className="offset-sm-2 col-sm-10">
                    <button onClick={onSubmit} className="btn btn-success w-100" disabled={!termsAccepted}>Submit
                    </button>
                </div>
            </div>
        </form>
    </div>

};

export default SettingsTab;
