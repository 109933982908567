import {iC6RestfulModel} from "@carbonorm/carbonnode";
import {uploadImage} from "@carbonorm/carbonreact";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import {RestTableInterfaces} from "api/rest/C6";
import Flagged_Entities from "api/rest/Flagged_Entities";
import Popup from "components/Popup/Popup";
import {ReactElement, useState} from "react";


export const contentFlaggingReasons = {
    "Inappropriate Content": "The material violates community guidelines, such as containing explicit, offensive, or harmful content.",
    "Copyright Infringement": "The content appears to infringe on someone else's intellectual property rights.",
    "Misinformation or Fake News": "The information presented is false, misleading, or promotes unverified claims.",
    "Spam or Irrelevant": "The content is unsolicited, promotional, or not relevant to the intended topic or audience.",
    "Harassment or Hate Speech": "The material includes targeted harassment or promotes hate speech against individuals or groups.",
    "Safety Concerns": "The content potentially poses a risk to viewer safety, such as promoting dangerous activities.",
    "Privacy Violation": "The material appears to violate someone's privacy or shares personal information without consent.",
    "Technical Issues": "The content has technical problems, like broken links, or it disrupts the platform's functionality.",
    "Off-Topic or Irrelevant Comments": "The associated comments or discussions are not related to the content or are inappropriate.",
    "Quality Concerns": "The content lacks the expected level or quality, such as being poorly written or having low production values."
};


export default function FlagEntity({open, handleClose, tableData, entity, entity_id, entity_owner_id}: {
    open: boolean,
    handleClose: () => any,
    tableData: iC6RestfulModel,
    entity: RestTableInterfaces,
    entity_id: string,
    entity_owner_id: string,
}): ReactElement {

    const flaggingReasons = Object.keys(contentFlaggingReasons);

    const [reason, setReason] = useState<string>(flaggingReasons[0]);
    const [additionalInformation, setAdditionalInformation] = useState<string>('');
    const [uploadedImage, setUploadedImage] = useState<string>('');
    const [agreeToTermsOfService, setAgreeToTermsOfService] = useState<boolean>(false);

    const whoami = getCurrentLoggedInUser();
    const postFlag = async () => Flagged_Entities.Post({
        flagged_entity_id: entity_id,
        flagged_entity_report_from: whoami?.user_id,
        flagged_entity_report_user: entity_owner_id,
        flagged_entity_report_reason: reason + ' - ' + contentFlaggingReasons[reason],
        flagged_entity_report_message: additionalInformation,
        flagged_entity_image: uploadedImage,
        flagged_entity_json: JSON.stringify(entity, undefined, 2),
        flagged_entity_table_json: JSON.stringify(tableData, undefined, 2),
    })?.then(() => {
        handleClose();
    })

    return <Popup open={open} handleClose={handleClose}>
        <div className="card card-warning">
            <div className="card-header">
                <h3 className="card-title">Report Entity</h3>
            </div>

            <div className="card-body">
                <form>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Why are you flagging this content for further review?</label>
                                <select className="custom-select" onChange={(event) => {
                                    setReason(event.target.value);
                                }}>
                                    {flaggingReasons.map((reason, key) => <option key={key}>{reason}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <p>{contentFlaggingReasons[reason]}</p>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group"><label>Additional Information</label>
                                <textarea className="form-control" rows={3}
                                          value={additionalInformation}
                                          placeholder="Please provide any further information here!"
                                          onChange={event => {
                                    setAdditionalInformation(event.target.value);
                                }}/>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group"><label>Flagging data <small>(This cannot be edited)</small></label>
                                <textarea className="form-control" rows={8} placeholder={JSON.stringify(entity, undefined, 2)} disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="custom-file" onClick={uploadImage((dataUriBase64)=>{
                            setUploadedImage(dataUriBase64);
                        })}>
                            <input className="custom-file-input" id="customFile" />
                            <label className="custom-file-label" htmlFor="customFile">
                                {uploadedImage ? <img src={uploadedImage} alt={'evidence'}/> : 'Upload image evidence (optional)'}
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div
                            className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                            <input type="checkbox" className="custom-control-input" id="customSwitch3" checked={agreeToTermsOfService} onClick={(_event) => {
                                setAgreeToTermsOfService(!agreeToTermsOfService);
                            }}/>
                            <label className="custom-control-label" htmlFor="customSwitch3">I agree to Terms of Service</label>
                        </div>
                    </div>
                </form>
            </div>

            <div className="card-footer">
                <button type="submit" className="btn btn-default" onClick={handleClose}>Cancel</button>
                <button type="submit" className="btn btn-warning float-right" onClick={postFlag}>Send Report</button>
            </div>

        </div>
    </Popup>


}