// WalletModal.tsx
import { useEffect, useRef } from 'react';
import { Card, Button } from 'react-bootstrap';
import styles from 'style.module.scss';

interface WalletCardProps {
    show: boolean;
    handleClose: () => void;
    addWallet: () => void;  // Add this line to accept the prop
}

const WalletCard: React.FC<WalletCardProps> = ({ show, handleClose, addWallet }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                handleClose();
            }
        };

        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show, handleClose]);

    if (!show) return null;

    return (
        <div className={styles.walletModalContainer} ref={modalRef}>
            <div className={styles.walletModalTitleBubble}>
                <h2 className={styles.walletModalTitle}>Let's Add a New Wallet</h2>
            </div>
            <Card className={styles.walletCard}>
                <Card.Body className={styles.walletCardBody}>
                    <Button className={styles.closeWalletCardButton} onClick={handleClose} aria-label="Close">
                        X
                    </Button>
                    <div className={styles.walletModalLabelSelect}>
                        <h3 className={styles.walletModalSubtitle}>Current Wallet:</h3>
                        <select className={styles.walletModalSelect} defaultValue="default">
                            <option value="default" disabled>Select Wallet</option>
                            <option>Phantom</option>
                            <option>Backpack</option>
                            <option>Glow</option>
                            <option>Solflare</option>
                        </select>
                    </div>
                    <ul className={styles.walletModalInstructions}>
                        <li className={styles.walletModalInstructionItem} data-number="1">
                            Switch to a different wallet within Phantom/Backpack/Glow/Solflare.
                        </li>
                        <li className={styles.walletModalInstructionItem} data-number="2">
                            <Button className={styles.defaultAddButton}>Change Wallet</Button>
                        </li>
                        <li className={styles.walletModalInstructionItem} data-number="3">
                            Are you adding a ledger wallet? Ledger will require you to send a small transaction fee to verify ownership.
                        </li>
                        <li className={styles.walletModalInstructionItem} data-number="4">
                            <Button className={styles.defaultAddButton}>Sign Message to Add Wallet</Button>
                        </li>
                    </ul>
                </Card.Body>
            </Card>
            <Button variant="primary" className='defaultAddButton' onClick={(event) => {
                event.stopPropagation();  // Prevent the click from being considered an outside click
                addWallet();
            }}>
                Add Wallet
            </Button>
        </div>
    );
};

export default WalletCard;