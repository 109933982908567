import CallerRequestListItem from "pages/Admin/CallerRequests/CallerRequestListItem";
import Voltxt from "Voltxt";

const CallerRequests =  () => {

    const {caller_requests} = Voltxt.instance.state;

    return <div className="card">
        <div className="card-header">
            <h3 className="card-title">Caller Requests</h3>
            <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse"
                        title="Collapse">
                    <i className="fas fa-minus"></i>
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
                    <i className="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div className="card-body p-0">
            <table className="table table-striped projects">
                <thead>
                <tr>
                    <th style={{width: "20%"}}>
                        User
                    </th>
                    <th style={{width: "20%"}}>
                        Request Status
                    </th>
                    <th>
                        Completed By
                    </th>
                    <th>
                        Message
                    </th>
                    <th>
                        Response
                    </th>
                </tr>
                </thead>
                <tbody>
                {caller_requests?.map((caller_request) => <CallerRequestListItem caller_request={caller_request}/>)}
                </tbody>
            </table>
        </div>

    </div>
}

export default CallerRequests;