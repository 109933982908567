import getStyles from "api/hoc/getStyles";
import newImage from "api/hoc/newImage";
import {iProjects} from "api/rest/C6";
import defaultAvatar from "assets/img/defaultAvatar.svg";
import Voltxt from "Voltxt";
import photo1 from "assets/img/template/photo1.png";
import classNames from "classnames";
import moment from "moment/moment";
import {PropsWithChildren} from "react";

export default function Overview({project}: PropsWithChildren<{
    project: iProjects,
}>) {

    const {users, photos, user_id} = Voltxt.instance.state

    const projectLeader = users?.find((user) => user.user_id === project?.project_leader),
        styles = getStyles();

    const isMyProject = projectLeader?.user_id === user_id;

    const overviewPicture = photos?.filter(image => image.photo_description === 'Overview' && image?.user_id === projectLeader?.user_id)
        .sort((a, b) => parseInt(moment(a.photo_upload_date).format('YYYYMMDD')) - parseInt(moment(b.photo_upload_date).format('YYYYMMDD'))).shift();

    return <div className="card card-widget widget-user shadow">

        <div className="widget-user-header"
             style={{backgroundImage: `url(${overviewPicture?.photo_src ?? photo1})`}}
             onClick={() => {
                 if (isMyProject && project?.project_id) {
                     newImage({
                         parent_id: project.project_id,
                         description: 'Overview',
                     })()
                 }
             }}>

            <h3 className="widget-user-username">{projectLeader?.user_username}</h3>

            <h5 className="widget-user-desc">Pro Caller</h5>

        </div>

        <div className="widget-user-image">
            <img className={classNames(styles.imgCircle, styles.imgLg, styles.mr5)}
                 src={projectLeader?.user_profile_pic ?? defaultAvatar} alt="User Avatar"/>
        </div>
        <div className="card-footer">
            <div className="row">
                <div className="col-sm-4 border-right">
                    <div className="description-block">
                        <h5 className="description-header">{project?.project_cost}</h5>
                        <div className="description-text">Subscription cost</div>
                    </div>

                </div>

                <div className="col-sm-4 border-right">
                    <div className="description-block">
                        <h5 className="description-header">14</h5>
                        <span className="description-text">Subscribers on track</span>
                    </div>

                </div>

                <div className="col-sm-4">
                    <div className="description-block">
                        <h5 className="description-header">{project?.project_overview_bottom}</h5>
                        <span className="description-text">{project?.project_overview_top}</span>
                    </div>

                </div>

            </div>

        </div>
    </div>

};

