import Voltxt from "Voltxt";
import getStyles from "api/hoc/getStyles";
import classNames from "classnames";
import {ContentHeader} from 'components';
//import Chat from "components/chat/Chat";
import Advertisement from "pages/projects/Advertisement";
import Overview from "pages/projects/Overview";
//import Search from "../search/Search";

const Dashboard = () => {

    const {projects} = Voltxt.instance.state;

    const affinity = getStyles();

    return (
        <div>
            <ContentHeader title="Dashboard" style={{fontFamily: 'RadixRegular'}}/>
            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        {projects?.map((project, index) => <div key={index} className="col-md-12 col-lg-6 col-xl-4">
                            <Overview project={project} />
                        </div>)}
                    </div>
                    <div className={classNames(affinity.card, affinity.cardSuccess, affinity.test)}>
                        <div className="card-body">
                            <div className="row">
                                {projects?.map((project, index) => <div key={index} className="col-md-12 col-lg-6 col-xl-4">
                                    <Advertisement project={project} />
                                </div>)}
                            </div>
                        </div>
                    </div>

                   {/* 
                    <Chat />

                    <Search />*/}

                </div>
            </section>
        </div>
    );
};

export default Dashboard;
