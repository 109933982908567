import {
    iPostC6RestResponse,
    restRequest,
    GET,
    POST,
    PUT,
    DELETE,
    iDeleteC6RestResponse,
    iGetC6RestResponse,
    iPutC6RestResponse,
    removeInvalidKeys,
    iAPI,
    Modify
} from "@carbonorm/carbonnode";
import {AxiosResponse} from "axios";
import {C6, chat_messages, iChat_Messages, RestTableNames} from "./C6";
import Voltxt from "Voltxt";

/**
CREATE TABLE `carbon_chat_messages` (
  `chat_message_id` binary(16) NOT NULL,
  `chat_message_from_user` binary(16) NOT NULL,
  `chat_private_group` binary(16) DEFAULT NULL,
  `chat_message` varchar(255) NOT NULL,
  `chat_message_datetime` datetime DEFAULT CURRENT_TIMESTAMP,
  `chat_message_advertisement` smallint DEFAULT '0',
  `chat_message_unfiltered` varchar(255) DEFAULT NULL,
  PRIMARY KEY (`chat_message_id`),
  UNIQUE KEY `chat_chat_messages_chat_message_id_uindex` (`chat_message_id`),
  KEY `chat_chat_messages_dig_chat_advertisements_advertisement_id_fk` (`chat_message_advertisement`),
  KEY `chat_chat_messages_users_ID_fk_2` (`chat_message_from_user`),
  CONSTRAINT `carbon_chat_messages_carbon_carbons_entity_pk_fk` FOREIGN KEY (`chat_message_id`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `chat_chat_messages_users_ID_fk` FOREIGN KEY (`chat_message_from_user`) REFERENCES `carbon_users` (`user_id`) ON DELETE CASCADE ON UPDATE CASCADE
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;
**/

type GetCustomAndRequiredFields = {}

type GetRequestTableOverrides = {}

// required parameters, optional parameters, parameter type overrides, response, and table names
const Get = restRequest<GetCustomAndRequiredFields, iChat_Messages, GetRequestTableOverrides, iGetC6RestResponse<iChat_Messages>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_messages.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        request.success ??= 'Successfully received chat_messages!'
        request.error ??= 'An unknown issue occurred creating the chat_messages!'
        return request
    },
    responseCallback: (response, _request) => {
        const responseData = response?.data?.rest;
         Voltxt.instance.updateRestfulObjectArrays<iChat_Messages>({
            dataOrCallback: Array.isArray(responseData) ? responseData : [responseData],
            stateKey: "chat_messages",
            uniqueObjectId: C6.chat_messages.PRIMARY_SHORT as (keyof iChat_Messages)[]
        })
    }
});

type PutCustomAndRequiredFields = {}

type PutRequestTableOverrides = {}

export function putState(response: AxiosResponse<iPutC6RestResponse<iChat_Messages>>, request: iAPI<Modify<iChat_Messages, PutRequestTableOverrides>> & PutCustomAndRequiredFields) {
     Voltxt.instance.updateRestfulObjectArrays<iChat_Messages>({
        dataOrCallback: [
            removeInvalidKeys<iChat_Messages>({
                ...request,
                ...response?.data?.rest,
            }, C6.TABLES)
        ],
        stateKey: "chat_messages",
        uniqueObjectId: chat_messages.PRIMARY_SHORT as (keyof iChat_Messages)[]
    })
}

const Put = restRequest<PutCustomAndRequiredFields, iChat_Messages, PutRequestTableOverrides, iPutC6RestResponse<iChat_Messages>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_messages.TABLE_NAME,
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success ??= 'Successfully updated chat_messages data!'
        request.error ??= 'An unknown issue occurred updating the chat_messages data!'
        return request
    },
    responseCallback: putState
});

type PostCustomAndRequiredFields = {}

type PostRequestTableOverrides = {}

export function postState(response: AxiosResponse<iPostC6RestResponse<iChat_Messages>>, request: iAPI<Modify<iChat_Messages, PostRequestTableOverrides>> & PostCustomAndRequiredFields, id: string | number | boolean) {
    if ('number' === typeof id || 'string' === typeof id) {
        if (1 !== chat_messages.PRIMARY_SHORT.length) {
            console.error("C6 received unexpected result's given the primary key length");
        } else {
            request[chat_messages.PRIMARY_SHORT[0]] = id
        }
    }
     Voltxt.instance.updateRestfulObjectArrays<iChat_Messages>({
        dataOrCallback: undefined !== request.dataInsertMultipleRows
            ? request.dataInsertMultipleRows.map((request, index) => {
                return removeInvalidKeys<iChat_Messages>({
                    ...request,
                    ...(index === 0 ? response?.data?.rest : {}),
                }, C6.TABLES)
            })
            : [
                removeInvalidKeys<iChat_Messages>({
                    ...request,
                    ...response?.data?.rest,
                }, C6.TABLES)
            ],
        stateKey: "chat_messages",
        uniqueObjectId: chat_messages.PRIMARY_SHORT as (keyof iChat_Messages)[]
    })
}

const Post = restRequest<PostCustomAndRequiredFields, iChat_Messages, PostRequestTableOverrides, iPostC6RestResponse<iChat_Messages>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_messages.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Successfully created the chat_messages data!'
        request.error ??= 'An unknown issue occurred creating the chat_messages data!'
        return request
    },
    responseCallback: postState
});

type DeleteCustomAndRequiredFields = {}

type DeleteRequestTableOverrides = {}

export function deleteState(_response: AxiosResponse<iDeleteC6RestResponse<iChat_Messages>>, request: iAPI<Modify<iChat_Messages, DeleteRequestTableOverrides>> & DeleteCustomAndRequiredFields) {
     Voltxt.instance.deleteRestfulObjectArrays<iChat_Messages>({
        dataOrCallback: [
            request
        ],
        stateKey: "chat_messages",
        uniqueObjectId: chat_messages.PRIMARY_SHORT as (keyof iChat_Messages)[]
    })
}

const Delete = restRequest<DeleteCustomAndRequiredFields, iChat_Messages, DeleteRequestTableOverrides, iDeleteC6RestResponse<iChat_Messages>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_messages.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success ??= 'Successfully removed the chat_messages data!'
        request.error ??= 'An unknown issue occurred removing the chat_messages data!'
        return request
    },
    responseCallback: deleteState
});

const Chat_Messages = {
    // Export all GET, POST, PUT, DELETE functions for each table
    Get,
    Post,
    Put,
    Delete,
}

export default Chat_Messages;
