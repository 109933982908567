import {AccessDenied} from "@carbonorm/carbonreact";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import logout from "api/hoc/logout";
import {ppr} from "api/hoc/passPropertiesAndRender";
import logo from "assets/img/logo.svg";
import Chat from "components/chat/Chat";
import ForgetPassword from "modules/forgot-password/ForgotPassword";
import Login from "modules/login/Login";
import Main from "modules/main/Main";
import RecoverPassword from "modules/recover-password/RecoverPassword";
import Register from "modules/register/Register";
import About from "pages/about/About";
import Advertising from "pages/about/business/advertising/Advertising";
import Careers from "pages/about/business/careers/Careers";
import ContactUs from "pages/about/business/contact-us/Contact-Us";
import Enterprise from "pages/about/business/enterprise/Enterprise";
import Community from "pages/about/community/Community";
import Documentation from "pages/about/documentation/Documentation";
import MeetTheTeam from "pages/about/meet-the-team/Meet-The-Team";
import OurMission from "pages/about/our-mission/Our-Mission";
import WhatIsVoltex from "pages/about/what-is-voltex/What-Is-Voltex";
import CallerRequests from "pages/Admin/CallerRequests/CallerRequests";
import FlaggedEntities from "pages/Admin/FlaggedEntities/FlaggedEntities";
import Blank from "pages/Blank";
import Dashboard from "pages/dashboard/Dashboard";
import Form from "pages/form/Form";
import Home from "pages/home/Home";
import Profile from "pages/profile/Profile";
import {ProjectRoute} from "pages/projects/Project";
import Projects from "pages/projects/Projects";
import Search from "pages/search/Search";
import Wallet from "pages/wallet/Wallet";
import React from "react";
import {useContext} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Voltxt from "Voltxt";


export default function Routing() {

    const {user_id} = useContext(Voltxt.instance.context);

    console.log("ROUTING TSX RENDER");

    if (undefined === user_id) {

        console.log('undefined === user_id')

        return (
            <div className="preloader flex-column justify-content-center align-items-center">
                <img
                    className="rounded-circle"
                    src={logo}
                    alt="AdminLTELogo"
                    height={60}
                    width={60}
                />
            </div>
        );
    }

    const whoami = getCurrentLoggedInUser()

    if (1 === whoami?.user_is_disabled) {

        return <React.Fragment>
            <AccessDenied/>
            <button className={'btn btn-primary'} onClick={() => {
                logout(false)
            }}>
                Logout
            </button>
        </React.Fragment>

    }

    const dynamicRoutes = '' === user_id
        ? [
            <Route path="/login" element={<Login/>}/>,
            <Route path="/register" element={<Register/>}/>,
            <Route path="/forgot-password" element={<ForgetPassword/>}/>,
            <Route path="/recover-password" element={<RecoverPassword/>}/>,
            <Route path="/" element={<Navigate to={'/about'}/>}/>,
            <Route path="/*" element={<Navigate to={'/about'}/>}/>,
        ]
        : [
            <Route path="/chat/:group_id" element={<Chat/>}/>,
            <Route path="/moderation/admin/"
                   element={whoami?.user_is_administrator ? <Search/> : <AccessDenied/>}/>,
            <Route path="/moderation/caller/" element={<CallerRequests/>}/>,
            <Route path="/moderation/flagged/" element={<FlaggedEntities/>}/>,
            <Route path="/wallet" element={<Wallet/>}/>,
            <Route path="/projects" element={ppr(Projects, {})}/>,
            <Route path="/profile" element={<Navigate to={'/profile/' + whoami?.user_id}/>}/>,
            <Route path="/" element={<Navigate to={'/dashboard'}/>}/>,
            <Route path="/*" element={<Navigate to={'/dashboard'}/>}/>,
        ];


    return <Routes>
        <Route path="/" element={<Main/>}>
            <Route path="/about" element={<About/>}/>
            <Route path="/advertising" element={<Advertising/>}/>
            <Route path="/careers" element={<Careers/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
            <Route path="/enterprise" element={<Enterprise/>}/>
            <Route path="/community" element={<Community/>}/>
            <Route path="/documentation" element={<Documentation/>}/>
            <Route path="/meet-the-team" element={<MeetTheTeam/>}/>
            <Route path="/our-mission" element={<OurMission/>}/>
            <Route path="/what-is-voltex" element={<WhatIsVoltex/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/search" element={<Search/>}/>
            <Route path="/blank" element={<Blank/>}/>
            <Route path="/form" element={<Form/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/profile/:user_id" element={ppr(Profile, {})}/>
            <Route path="/project/:project_id" element={ppr(ProjectRoute, {})}/>
            {dynamicRoutes}
        </Route>
    </Routes>;


}