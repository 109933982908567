import {
    iPostC6RestResponse,
    restRequest,
    GET,
    POST,
    PUT,
    DELETE,
    iDeleteC6RestResponse,
    iGetC6RestResponse,
    iPutC6RestResponse,
    removeInvalidKeys,
    iAPI,
    Modify
} from "@carbonorm/carbonnode";
import {AxiosResponse} from "axios";
import {C6, flagged_entities, iFlagged_Entities, RestTableNames} from "./C6";
import Voltxt from "Voltxt";

/**
CREATE TABLE `carbon_flagged_entities` (
  `flagged_entity_report_id` binary(16) NOT NULL,
  `flagged_entity_id` binary(16) DEFAULT NULL,
  `flagged_entity_report_from` binary(16) DEFAULT NULL,
  `flagged_entity_report_message` varchar(255) DEFAULT NULL,
  `flagged_entity_response_message` varchar(255) DEFAULT NULL,
  `flagged_entity_responded_by` binary(16) DEFAULT NULL,
  `flagged_entity_report_reason` varchar(255) DEFAULT NULL,
  `flagged_entity_image` longblob,
  `flagged_entity_json` json NOT NULL,
  `flagged_entity_report_user` binary(16) DEFAULT NULL,
  `flagged_entity_table_json` json NOT NULL,
  `flagged_entity_report_created` datetime DEFAULT CURRENT_TIMESTAMP,
  PRIMARY KEY (`flagged_entity_report_id`),
  KEY `carbon_flagged_entities_carbon_carbons_entity_pk_fk` (`flagged_entity_report_from`),
  KEY `carbon_flagged_entities_carbon_carbons_entity_pk_fk2` (`flagged_entity_responded_by`),
  KEY `carbon_flagged_entities_carbon_carbons_entity_pk_fk3` (`flagged_entity_report_user`),
  KEY `carbon_flagged_entities_carbon_carbons_entity_pk_fk4` (`flagged_entity_id`),
  CONSTRAINT `carbon_flagged_entities_carbon_carbons_entity_pk_fk` FOREIGN KEY (`flagged_entity_report_from`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE SET NULL ON UPDATE CASCADE,
  CONSTRAINT `carbon_flagged_entities_carbon_carbons_entity_pk_fk2` FOREIGN KEY (`flagged_entity_responded_by`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE SET NULL ON UPDATE CASCADE,
  CONSTRAINT `carbon_flagged_entities_carbon_carbons_entity_pk_fk3` FOREIGN KEY (`flagged_entity_report_user`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE SET NULL ON UPDATE CASCADE,
  CONSTRAINT `carbon_flagged_entities_carbon_carbons_entity_pk_fk4` FOREIGN KEY (`flagged_entity_id`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE SET NULL ON UPDATE CASCADE
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;
**/

type GetCustomAndRequiredFields = {}

type GetRequestTableOverrides = {}

// required parameters, optional parameters, parameter type overrides, response, and table names
const Get = restRequest<GetCustomAndRequiredFields, iFlagged_Entities, GetRequestTableOverrides, iGetC6RestResponse<iFlagged_Entities>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: flagged_entities.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        request.success ??= 'Successfully received flagged_entities!'
        request.error ??= 'An unknown issue occurred creating the flagged_entities!'
        return request
    },
    responseCallback: (response, _request) => {
        const responseData = response?.data?.rest;
         Voltxt.instance.updateRestfulObjectArrays<iFlagged_Entities>({
            dataOrCallback: Array.isArray(responseData) ? responseData : [responseData],
            stateKey: "flagged_entities",
            uniqueObjectId: C6.flagged_entities.PRIMARY_SHORT as (keyof iFlagged_Entities)[]
        })
    }
});

type PutCustomAndRequiredFields = {}

type PutRequestTableOverrides = {}

export function putState(response: AxiosResponse<iPutC6RestResponse<iFlagged_Entities>>, request: iAPI<Modify<iFlagged_Entities, PutRequestTableOverrides>> & PutCustomAndRequiredFields) {
     Voltxt.instance.updateRestfulObjectArrays<iFlagged_Entities>({
        dataOrCallback: [
            removeInvalidKeys<iFlagged_Entities>({
                ...request,
                ...response?.data?.rest,
            }, C6.TABLES)
        ],
        stateKey: "flagged_entities",
        uniqueObjectId: flagged_entities.PRIMARY_SHORT as (keyof iFlagged_Entities)[]
    })
}

const Put = restRequest<PutCustomAndRequiredFields, iFlagged_Entities, PutRequestTableOverrides, iPutC6RestResponse<iFlagged_Entities>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: flagged_entities.TABLE_NAME,
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success ??= 'Successfully updated flagged_entities data!'
        request.error ??= 'An unknown issue occurred updating the flagged_entities data!'
        return request
    },
    responseCallback: putState
});

type PostCustomAndRequiredFields = {}

type PostRequestTableOverrides = {}

export function postState(response: AxiosResponse<iPostC6RestResponse<iFlagged_Entities>>, request: iAPI<Modify<iFlagged_Entities, PostRequestTableOverrides>> & PostCustomAndRequiredFields, id: string | number | boolean) {
    if ('number' === typeof id || 'string' === typeof id) {
        if (1 !== flagged_entities.PRIMARY_SHORT.length) {
            console.error("C6 received unexpected result's given the primary key length");
        } else {
            request[flagged_entities.PRIMARY_SHORT[0]] = id
        }
    }
     Voltxt.instance.updateRestfulObjectArrays<iFlagged_Entities>({
        dataOrCallback: undefined !== request.dataInsertMultipleRows
            ? request.dataInsertMultipleRows.map((request, index) => {
                return removeInvalidKeys<iFlagged_Entities>({
                    ...request,
                    ...(index === 0 ? response?.data?.rest : {}),
                }, C6.TABLES)
            })
            : [
                removeInvalidKeys<iFlagged_Entities>({
                    ...request,
                    ...response?.data?.rest,
                }, C6.TABLES)
            ],
        stateKey: "flagged_entities",
        uniqueObjectId: flagged_entities.PRIMARY_SHORT as (keyof iFlagged_Entities)[]
    })
}

const Post = restRequest<PostCustomAndRequiredFields, iFlagged_Entities, PostRequestTableOverrides, iPostC6RestResponse<iFlagged_Entities>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: flagged_entities.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Successfully created the flagged_entities data!'
        request.error ??= 'An unknown issue occurred creating the flagged_entities data!'
        return request
    },
    responseCallback: postState
});

type DeleteCustomAndRequiredFields = {}

type DeleteRequestTableOverrides = {}

export function deleteState(_response: AxiosResponse<iDeleteC6RestResponse<iFlagged_Entities>>, request: iAPI<Modify<iFlagged_Entities, DeleteRequestTableOverrides>> & DeleteCustomAndRequiredFields) {
     Voltxt.instance.deleteRestfulObjectArrays<iFlagged_Entities>({
        dataOrCallback: [
            request
        ],
        stateKey: "flagged_entities",
        uniqueObjectId: flagged_entities.PRIMARY_SHORT as (keyof iFlagged_Entities)[]
    })
}

const Delete = restRequest<DeleteCustomAndRequiredFields, iFlagged_Entities, DeleteRequestTableOverrides, iDeleteC6RestResponse<iFlagged_Entities>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: flagged_entities.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success ??= 'Successfully removed the flagged_entities data!'
        request.error ??= 'An unknown issue occurred removing the flagged_entities data!'
        return request
    },
    responseCallback: deleteState
});

const Flagged_Entities = {
    // Export all GET, POST, PUT, DELETE functions for each table
    Get,
    Post,
    Put,
    Delete,
}

export default Flagged_Entities;
