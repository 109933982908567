import { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Carousel } from 'react-bootstrap';
import styles from 'style.module.scss';

const Home = () => {
    // Add this new state to control the active tab
    const [activeTab, setActiveTab] = useState('top-charts');

    // Function to handle tab clicks
    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };

    const [recentActivities, setRecentActivities] = useState([
        'Someone just claimed a free product',
        'Someone just paid $147.00',
        'Someone just paid $100.00',
    ]);

    const [newActivity, setNewActivity] = useState('');

    // Function to cycle through activities with slide-right effect
    useEffect(() => {
        const allActivities = [
            'Someone just claimed a free product',
            'Someone just paid $147.00',
            'Someone just paid $100.00',
            'Someone just paid $250.00',
            'Someone just joined the Premium Membership',
            'Someone just subscribed to Stock Mastery',
            'Someone just unlocked the FX Masterclass',
            'Someone just claimed a free trial',
        ];

        const cycleActivities = setInterval(() => {
            setRecentActivities((prev) => {
                const nextIndex = (allActivities.indexOf(prev[prev.length - 1]) + 1) % allActivities.length;
                const newItem = allActivities[nextIndex];
                setNewActivity('');
                setTimeout(() => setNewActivity(newItem), 0);
                return [newItem, ...prev.slice(0, prev.length - 1)];
            });
        }, 3000); // Every 3 seconds

        return () => clearInterval(cycleActivities);
    }, []);

    return (
        <div className={styles.home}>
            <main>
                <Container>
                    {/* Hero Section */}
                    <section className={styles.hero}>
                        <h1>Voltxt is a better way to view products!</h1>
                        <p>We aim to create a user-friendly space for knowledge sharing, idea exchange, and community building. Our application is designed as a software-as-a-service (SaaS) platform, integrating seamlessly with Ethereum and Solana for transaction processing.</p>
                        <Form className={`d-flex justify-content-center mt-3 ${styles.searchBar}`}>
                            <Form.Control
                                type="text"
                                placeholder="Search 'trading'"
                                className="me-2"
                                style={{ width: '300px' }}
                            />
                            <Button variant="light">Search </Button>
                        </Form>
                    </section>
                    
                    {/* Stats Section */}
                    <section className={styles.stats}>
                        <Row className="text-center my-5">
                            <Col>
                                <h2>$000,000,000</h2>
                                <p>From Projects On Voltext!</p>
                            </Col>
                            <Col>
                                <h2>000,000,000</h2>
                                <p>Memberships Sold!</p>
                            </Col>
                            <Col>
                                <h2>000,000</h2>
                                <p>Projects to Choose From!</p>
                            </Col>
                        </Row>
                    </section>
                    
                    {/* Recent Activity Section */}
                    <section className={styles.recentActivity}>
                        <h2>Recent activity <span className="badge bg-info text-dark ms-2">LIVE</span></h2>
                        <ul className="list-group mt-3">
                            {recentActivities.map((activity, index) => (
                                <li key={index} className={`list-group-item ${activity === newActivity ? styles.slideRight : ''}`}>
                                    {activity}
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Trending Products Section */}
                    <section className={styles.trendingProducts}>
                        <h2>Trending Products</h2>
                        <p>Products currently in high demand.</p>
                        <Carousel>
                            <Carousel.Item>
                                <div className="d-flex justify-content-around">
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="MasterClass FX" />
                                        <h3>MasterClass FX</h3>
                                        <p>Become an FX Master with advanced strategies</p>
                                        <p>$29.95</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="CryptoBot Ultimate" />
                                        <h3>CryptoBot Ultimate</h3>
                                        <p>Automate your crypto trades and maximize profits</p>
                                        <p>$49.99/month</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Social Media Wizardry" />
                                        <h3>Social Media Wizardry</h3>
                                        <p>Boost your brand's presence with magic tricks</p>
                                        <p>$19.95</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="d-flex justify-content-around">
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Growth Hacker Pro" />
                                        <h3>Growth Hacker Pro</h3>
                                        <p>Maximize your social media reach with proven strategies</p>
                                        <p>$39.99</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Influencer Toolkit" />
                                        <h3>Influencer Toolkit</h3>
                                        <p>Complete toolkit for managing your influencer brand</p>
                                        <p>$59.99</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Marketing Genius" />
                                        <h3>Marketing Genius</h3>
                                        <p>Secrets to skyrocketing your online presence</p>
                                        <p>$29.99/month</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </section>
                    {/* Newly Added Section */}
                    <section className={styles.newlyAddedSection}>
                        <h2>Newly Added</h2>
                        <p>New projects on Voltxt</p>
                        <Carousel>
                            <Carousel.Item>
                                <div className="d-flex justify-content-around">
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="MasterClass FX" />
                                        <h3>MasterClass FX</h3>
                                        <p>Become an FX Master with advanced strategies</p>
                                        <p>$29.95</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="CryptoBot Ultimate" />
                                        <h3>CryptoBot Ultimate</h3>
                                        <p>Automate your crypto trades and maximize profits</p>
                                        <p>$49.99/month</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Social Media Wizardry" />
                                        <h3>Social Media Wizardry</h3>
                                        <p>Boost your brand's presence with magic tricks</p>
                                        <p>$19.95</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="d-flex justify-content-around">
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Growth Hacker Pro" />
                                        <h3>Growth Hacker Pro</h3>
                                        <p>Maximize your social media reach with proven strategies</p>
                                        <p>$39.99</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Influencer Toolkit" />
                                        <h3>Influencer Toolkit</h3>
                                        <p>Complete toolkit for managing your influencer brand</p>
                                        <p>$59.99</p>
                                    </div>
                                    <div className={styles.productCard}>
                                        <img src="https://via.placeholder.com/300x200" alt="Marketing Genius" />
                                        <h3>Marketing Genius</h3>
                                        <p>Secrets to skyrocketing your online presence</p>
                                        <p>$29.99/month</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </section>

                    {/* Top Charts Section */}
                    <section className={styles.topChartsSection}>
                        <h2>Top Charts</h2>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <span className={`nav-link ${activeTab === 'top-charts' ? 'active' : ''}`} onClick={() => handleTabClick('top-charts')}>
                                    Top Charts
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link ${activeTab === 'top-trending' ? 'active' : ''}`} onClick={() => handleTabClick('top-trending')}>
                                    Top Trending
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link ${activeTab === 'top-rated' ? 'active' : ''}`} onClick={() => handleTabClick('top-rated')}>
                                    Top Rated
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link ${activeTab === 'free-trials' ? 'active' : ''}`} onClick={() => handleTabClick('free-trials')}>
                                    Free Trials
                                </span>
                            </li>
                        </ul>

                        <div className="tab-content mt-3">
                            <div className={`tab-pane fade ${activeTab === 'top-charts' ? 'show active' : ''}`}>
                                <ol>
                                    <li><strong>1. Crypto Crusaders:</strong> A strategic community for elite crypto traders. <span>Free</span></li>
                                    <li><strong>2. Wealth Navigators:</strong> Exclusive education on wealth-building strategies. <span>$250.00/month</span></li>
                                    <li><strong>3. Sports Guru Picks:</strong> Precision sports betting advice from top experts. <span>$19.99/month</span></li>
                                    <li><strong>4. Trend Master:</strong> Automated trading signals based on market trends. <span>$99.99/month</span></li>
                                    <li><strong>5. Forex Mavericks:</strong> Learn to navigate the forex market like a pro. <span>$79.99/month</span></li>
                                    <li><strong>6. Option Titans:</strong> Master options trading with advanced strategies. <span>$149.00</span></li>
                                    <li><strong>7. Altcoin Academy:</strong> In-depth analysis and alerts on trending altcoins. <span>$49.99/month</span></li>
                                    <li><strong>8. DeFi Dominators:</strong> Explore decentralized finance opportunities. <span>$99.00</span></li>
                                    <li><strong>9. NFT Insiders:</strong> Learn how to profit from non-fungible tokens. <span>$29.99/month</span></li>
                                    <li><strong>10. Stock Visionaries:</strong> Exclusive stock trading insights from top analysts. <span>$199.00/month</span></li>
                                </ol>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'top-trending' ? 'show active' : ''}`}>
                                <ol>
                                    <li><strong>1. Apex Pro Sports:</strong> Daily sports betting plays and precision algorithms. <span>$9.95/day</span></li>
                                    <li><strong>2. Parlay Raj:</strong> Advanced sports betting strategies and parlay picks. <span>$14.99/week</span></li>
                                    <li><strong>3. Forex Phoenix:</strong> Proven forex signals for consistent gains. <span>$49.99/month</span></li>
                                    <li><strong>4. Algo Trader's Hub:</strong> Exclusive access to algorithmic trading techniques. <span>$99.00/month</span></li>
                                    <li><strong>5. NFT Market Watch:</strong> Stay ahead of NFT trends and profit opportunities. <span>$59.99/month</span></li>
                                    <li><strong>6. Binary Wizards Club:</strong> Expert binary options strategies and analysis. <span>$29.99/month</span></li>
                                    <li><strong>7. Whale Signals Network:</strong> Get access to premium crypto trading signals. <span>$199.00/month</span></li>
                                    <li><strong>8. Growth Stocks Pro:</strong> Learn to identify and profit from growth stocks. <span>$149.99/month</span></li>
                                    <li><strong>9. Hedge Fund Mastery:</strong> Comprehensive hedge fund investment strategies. <span>$199.99/month</span></li>
                                    <li><strong>10. Day Trader's Workshop:</strong> Advanced day trading skills for the modern market. <span>$79.99/month</span></li>
                                </ol>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'top-rated' ? 'show active' : ''}`}>
                                <ol>
                                    <li><strong>1. Elite Forex Academy:</strong> Learn the secrets of successful forex traders. <span>$199.00/month</span></li>
                                    <li><strong>2. Crypto Signals Plus:</strong> Get accurate and profitable crypto signals daily. <span>$99.99/month</span></li>
                                    <li><strong>3. Wealth Builders Club:</strong> Access wealth-building insights from top experts. <span>$49.99/month</span></li>
                                    <li><strong>4. Option Gurus Network:</strong> Master the art of options trading with the best. <span>$79.99/month</span></li>
                                    <li><strong>5. Stock Traders Paradise:</strong> Exclusive strategies to maximize stock trading profits. <span>$149.00/month</span></li>
                                    <li><strong>6. Altcoin Traders Hub:</strong> In-depth analysis and alerts on trending altcoins. <span>$99.99/month</span></li>
                                    <li><strong>7. Day Trading Mastermind:</strong> Learn from top day traders and maximize gains. <span>$49.99/month</span></li>
                                    <li><strong>8. Swing Trading Legends:</strong> Proven swing trading techniques for success. <span>$59.99/month</span></li>
                                    <li><strong>9. High Frequency Masterclass:</strong> Learn to excel in high-frequency trading. <span>$199.00</span></li>
                                    <li><strong>10. Tech Stocks Titans:</strong> Stay ahead in the tech stocks market with top insights. <span>$89.99/month</span></li>
                                </ol>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'free-trials' ? 'show active' : ''}`}>
                                <ol>
                                    <li><strong>1. Sports Betting Elite:</strong> Join the exclusive sports betting community. <span>Free</span></li>
                                    <li><strong>2. Crypto Wealth Academy:</strong> Learn profitable crypto trading strategies. <span>Free</span></li>
                                    <li><strong>3. NFT Market Mastery:</strong> Unlock profitable NFT trading insights. <span>Free</span></li>
                                    <li><strong>4. Parlay Raj Lite:</strong> Advanced sports betting parlay strategies for free. <span>Free</span></li>
                                    <li><strong>5. Stock Wizards Network:</strong> Free stock trading strategies and analysis. <span>Free</span></li>
                                    <li><strong>6. Whale Trading Network:</strong> Access free premium trading signals from top analysts. <span>Free</span></li>
                                    <li><strong>7. Day Trading 101:</strong> Free resources for beginner day traders. <span>Free</span></li>
                                    <li><strong>8. Forex Signals Starter:</strong> Proven free forex signals for beginners. <span>Free</span></li>
                                    <li><strong>9. Binary Options Academy:</strong> Learn to master binary options trading for free. <span>Free</span></li>
                                    <li><strong>10. Altcoin Alerts Club:</strong> Get free alerts on trending altcoins. <span>Free</span></li>
                                </ol>
                            </div>
                        </div>
                    </section>
                    <section className={styles.discoverSection}>
                        <Container>
                            <Row>
                                <Col>
                                    <h2>Discover why millions of people post their projects to Voltxt</h2>
                                    <ul className={styles.discoverList}>
                                        <li>
                                            <i className="fas fa-search"></i>
                                            <div>
                                                <h3>Browse all types of products</h3>
                                                <p>Discover NFT projects you didn't even know existed!</p>
                                            </div>
                                        </li>
                                        <li>
                                            <i className="fas fa-headset"></i>
                                            <div>
                                                <h3>Get customer support</h3>
                                                <p>Navigating this world is hard. We're here to make sure you're in the best hands.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <i className="fas fa-cogs"></i>
                                            <div>
                                                <h3>Manage your memberships</h3>
                                                <p>Manage your digital subscriptions and project memberships in one place.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <img src="https://cdn.dribbble.com/users/1980965/screenshots/7440967/media/1e69be28428987bb6ca3d613e4114e37.gif" alt="Globe Illustration" className={styles.globeImage} />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Container>
            </main>
        </div>
    );
};

export default Home;
