import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import getStyles from "api/hoc/getStyles";
import Voltxt from "Voltxt";
import NewPost from "pages/profile/NewPost";
import ProfileTabs from "pages/profile/ProfileTabs";
import NewProject from "pages/projects/NewProject";
import Project from "pages/projects/Project";
import {PropsWithChildren, useRef, useState} from 'react';
import {ContentHeader} from 'components';

import defaultAvatar from 'assets/img/defaultAvatar.svg';

import {Badge, Overlay, Tooltip} from 'react-bootstrap';
import {WithRouter} from "api/hoc/passPropertiesAndRender";
import newProfilePicture from "../../api/hoc/newProfilePicture";

const Profile = (params: PropsWithChildren<WithRouter>) => {

    const {projects, subscription, users, posts} = Voltxt.instance.state;
    const whoami = getCurrentLoggedInUser()
    const styles = getStyles();
    const profileId = params.params.user_id;
    const [showPublicPrivateTooltip, setShowPublicPrivateTooltip] = useState(false);
    const publicPrivateTooltip = useRef(null);
    const [viewAsPublic, setViewAsPublic] = useState(profileId !== whoami?.user_id);

    const isMyProfile = whoami?.user_id === profileId;

    const profile = isMyProfile
        ? whoami
        : users?.find((user) => user.user_id === profileId);

    const myProjects = projects?.filter((project) => project.project_leader === profileId);

    const myPosts = posts?.filter((post) => post.post_author === profileId);

    const mySubscribers = myProjects?.reduce((subs, project) => {
        return subs + (subscription?.filter((sub) => sub.subscription_to_entity_id === project.project_id).length ?? 0);
    }, 0);

    const imSubscribed = isMyProfile || undefined !== myPosts

    let body = <p>Loading...</p>;

    if (viewAsPublic) {

        if (undefined === myProjects) {
            body = <p>The user ({profile?.user_username}) has not created any projects!</p>
        } else {
            body = <Project project={{
                project_leader: whoami?.user_id,
            }} blur/>
        }

    } else if (isMyProfile) {

        if (undefined === myProjects) {
            body = <><NewProject/><ProfileTabs/></>
        } else {
            body = <><NewPost/><ProfileTabs/></>
        }

    } else { // someone else's profile we are subscribed to

        if (undefined === myProjects) {
            body = <p>The user ({profile?.user_username}) has not created any projects!</p>
        } else {
            body = <Project project={{
                project_leader: whoami?.user_id,
            }} blur/>
        }

    }


    return <>
        <ContentHeader title="Profile">
            {whoami?.user_id && <>
                <Badge ref={publicPrivateTooltip} variant="primary"
                       onClick={() => setShowPublicPrivateTooltip(!showPublicPrivateTooltip)}>
                    {viewAsPublic ? 'Public' : 'Private'}
                </Badge>
                <Overlay target={publicPrivateTooltip.current} show={showPublicPrivateTooltip} placement="right">
                    {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                            View as <p onClick={() => setViewAsPublic(!viewAsPublic)}>{viewAsPublic ? 'owner' : 'public'} here</p>
                        </Tooltip>
                    )}
                </Overlay>
            </>}
        </ContentHeader>
        <section className={styles.content}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <div onClick={newProfilePicture}>
                                        <img
                                            className="img-circle"
                                            src={whoami?.user_profile_pic || defaultAvatar}
                                            style={{cursor: 'pointer'}}
                                            alt="User"
                                            width={90}
                                            height={90}
                                            title="Click to change profile picture"

                                        />
                                    </div>
                                </div>
                                <h3 className="profile-username text-center">
                                    {profile?.user_first_name} {profile?.user_last_name}
                                </h3>
                                <p className="text-muted text-center">{profile?.user_username}</p>
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Projects</b>
                                        <span className="float-right">{myProjects?.length}</span>
                                    </li>
                                    {isMyProfile && false === viewAsPublic && <li className="list-group-item">
                                        <b>Posts</b>
                                        <span className="float-right">{myPosts?.length}</span>
                                    </li>}
                                    <li className="list-group-item">
                                        <b>Subscribers</b>
                                        <span className="float-right">{mySubscribers}</span>
                                    </li>
                                </ul>
                                {(!isMyProfile || viewAsPublic) && (imSubscribed
                                    ? <button type="submit" className="btn btn-light w-100">Unsubscribe</button>
                                    : <button type="submit" className="btn btn-success w-100">Subscribe</button>)}
                            </div>
                            {/* /.card-body */}
                        </div>


                        {!(isMyProfile || whoami?.user_education || whoami?.user_skills || whoami?.user_notes)
                            ? null
                            : <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        About me
                                    </h3>
                                </div>
                                <div className="card-body">
                                    {(isMyProfile || whoami?.user_twitter_screen_name) && <>
                                        <strong>
                                            <i className="fab fa-x-twitter mr-1"/>
                                            X (Twitter)
                                        </strong>
                                        <p className="text-muted">
                                            {whoami?.user_twitter_screen_name ??
                                                <button className="btn btn-block" style={{
                                                    backgroundColor: "#000000",
                                                    color: "#fff"
                                                }}
                                                        onClick={() => {
                                                            window.location.replace('/bootstrap/twitter/login/')
                                                        }}>
                                                    <i className="fab fa-x-twitter mr-2"/>
                                                </button>}
                                        </p>
                                        <hr/>
                                    </>}
                                    {(isMyProfile || whoami?.user_discord_username) && <>
                                        <strong>
                                            <i className="fab fa-discord mr-1"/>
                                            Discord
                                        </strong>
                                        <p className="text-muted">
                                            {whoami?.user_discord_username ??
                                                <button className="btn btn-block" style={{backgroundColor: "#5865F2"}}
                                                        onClick={() => {
                                                            window.location.replace('/bootstrap/discord/login/')
                                                        }}>
                                                    <i className="fab fa-discord mr-2" aria-label={'discord'}/>
                                                </button>}
                                        </p>
                                        <hr/>
                                    </>}
                                    {whoami?.user_education && <>
                                        <strong>
                                            <i className="fas fa-book mr-1"/>
                                            Education
                                        </strong>
                                        <p className="text-muted">
                                            {whoami?.user_education}
                                        </p>
                                        <hr/>
                                    </>}
                                    <strong>
                                        <i className="fas fa-map-marker-alt mr-1"/>
                                        Location
                                    </strong>
                                    <p className="text-muted">Malibu, California</p>
                                    <hr/>
                                    {whoami?.user_skills && <>
                                        <strong>
                                            <i className="fas fa-pencil-alt mr-1"/>
                                            Skills
                                        </strong>
                                        <p className="text-muted">
                                            {whoami?.user_skills?.split(',').map((skill) =>
                                                <span
                                                    className="tag tag-danger badge-pill badge badge-info">{skill}</span>)}
                                        </p>
                                        <hr/>
                                    </>}
                                    {whoami?.user_notes && <>
                                        <strong>
                                            <i className="far fa-file-alt mr-1"/>
                                            Notes
                                        </strong>
                                        <p className="text-muted">
                                            {whoami?.user_notes}
                                        </p>
                                    </>}
                                </div>
                            </div>}


                    </div>
                    <div className="col-md-9">

                        {body}

                    </div>
                </div>
            </div>
        </section>
    </>
};

export default Profile;
