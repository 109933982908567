import { ContentHeader } from "components";
import FetchMarkdown from "components/FetchMarkdown/FetchMarkdown";

const About = () => {
  return (
    <div>
      <ContentHeader title="Welcome to Voltxt, we're glad you made it!" />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Voltxt is a better way to view products</h3>
                </div>
                <div className="card-body">
                    <div style={{ padding: '20px'}}>
                    <FetchMarkdown
                        url={"https://raw.githubusercontent.com/teamvoltex/AffinityDocumentation/main/README.md"}
                    />
                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Join the Discussion</h3>
                </div>
                <div className="card-body">
                  <p>
                    The launch is coming. Join our discussion forums and be a part of the excitement.
                  </p>
                </div>
                <div className="card-footer">
                  <a href="#join-now" className="btn btn-primary">Join Now</a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Core Features</h3>
                </div>
                <div className="card-body" style={{ padding: '20px' }}>
                  <ul style={{ marginLeft: '15px' }}>
                    <li>User Registration and Authentication</li>
                    <li>Forum Navigation</li>
                    <li>Search Functionality</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">For Registered Users</h3>
                </div>
                <div className="card-body" style={{ padding: '20px' }}>
                  <ul style={{ marginLeft: '15px' }}>
                    <li>Topic Creation and Management</li>
                    <li>Post Creation and Interaction</li>
                    <li>User Profile Management</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
