import {ContentHeader} from 'components';

const Blank = () => {
  return (
    <div>
      <ContentHeader title="Documentation" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Title</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  title="Collapse"
                  data-toggle="collapse"
                  aria-expanded="true"
                  data-target=".card-body"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body" id={'collapseExample'}>
              Start creating your amazing application!
            </div>
            <div className="card-footer">Footer</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blank;
