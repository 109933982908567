import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import getStyles from "api/hoc/getStyles";
import Voltxt from "Voltxt";
import {ContentHeader} from 'components';
import Advertisement from "pages/projects/Advertisement";
import NewCaller from "pages/projects/NewCaller";
import NewProject from "pages/projects/NewProject";
import Overview from "pages/projects/Overview";
import Project from "pages/projects/Project";

export default function Projects() {

    const style = getStyles();

    const whoami = getCurrentLoggedInUser();

    const myProjects = undefined === whoami
        ? undefined
        : Voltxt.instance.state.projects?.filter(project => project.project_leader === whoami?.user_id)

    console.log('all projects', Voltxt.instance.state.projects, 'myProjects', myProjects, whoami)

    return <div>
        <ContentHeader title="Start a new project"/>
        <section className="content">
            <div className="container-fluid">
                {whoami?.user_is_caller ? <NewProject/> : <NewCaller/>}
            </div>
        </section>
        <ContentHeader title="Your existing projects" showBreadcrumb={false}/>

        {(() => {
            switch (myProjects) {
                case undefined:
                    return <p>Projects Not Loaded!</p>
                case null:
                    return <p>Loading Projects...</p>
                default:
                    return myProjects?.map((project, index) => {

                        const main = <div className="col-lg-8 col-12">
                            <Project project={project}/>
                        </div>

                        const fill = <div className="col-lg-4 col-12">
                            <Overview project={project}/>
                            <Advertisement project={project}/>
                        </div>

                        return <div key={index} className={style.row}>
                            {index % 2 === 0 ? main : fill}
                            {index % 2 === 0 ? fill : main}

                        </div>

                    })
            }
        })()}

    </div>


};

