import Voltxt from "Voltxt";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import Caller_Requests from "api/rest/Caller_Requests";
import {useState} from "react";

export default function NewCaller () {

    const {caller_requests} = Voltxt.instance.state;

    const whoami = getCurrentLoggedInUser()

    const request = caller_requests?.find(request => request.caller_request_user === whoami?.user_id)

    const [requestMessage, setRequestMessage] = useState<string>(request?.caller_request_message ?? '');

    const submit = () => Caller_Requests.Post({
        caller_request_message: requestMessage,
        caller_request_user: whoami?.user_id,
    })

    const update = () => Caller_Requests.Put({
        ...request, // order here matters
        caller_request_message: requestMessage,
    })

    const isProcessing = null === request?.caller_request_status

    const statusText = isProcessing
        ? 'Your request (' + request.caller_request_id + ') is being processed! '
        : 'Your Request (' + request?.caller_request_id + ') to become a caller has been denied. Admin response: (' + (request?.caller_request_response ?? 'N/A') + ')';


    return <div className={"card card-" + (isProcessing ? 'info' : 'danger')}>
        <div className="card-header">
            <h3 className="card-title">
                {request ? statusText : 'Request to become a caller'}
            </h3>
        </div>
        <div className="form-horizontal">
            {isProcessing && <>
                <div className="card-body">
                    <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                            Request Message
                        </label>
                        <div className="col-sm-10">
                        <textarea
                            disabled={!isProcessing}
                            onChange={(event) => setRequestMessage(event.target.value)}
                            className="form-control"
                            value={requestMessage}
                            id="inputEmail3"
                            placeholder={requestMessage === '' ? "Please add any additional information here" : requestMessage}/>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <button type="submit" onClick={request ? update : submit}
                            className="btn btn-info float-right mr-3">{request ? 'Update' : 'Submit'}</button>
                    <button type="reset" className="btn btn-default float-right">Cancel</button>
                </div>
            </>}
        </div>
    </div>

}