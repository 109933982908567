import {
    iPostC6RestResponse,
    restRequest,
    GET,
    POST,
    PUT,
    DELETE,
    iDeleteC6RestResponse,
    iGetC6RestResponse,
    iPutC6RestResponse,
    removeInvalidKeys,
    iAPI,
    Modify
} from "@carbonorm/carbonnode";
import {AxiosResponse} from "axios";
import {C6, chat_groups, iChat_Groups, RestTableNames} from "./C6";
import Voltxt from "Voltxt";

/**
CREATE TABLE `carbon_chat_groups` (
  `chat_group_id` binary(16) NOT NULL,
  `chat_group_name` varchar(255) NOT NULL,
  `chat_group_created_by` binary(16) NOT NULL,
  PRIMARY KEY (`chat_group_id`),
  KEY `carbon_chat_groups_carbon_carbons_entity_pk_fk2` (`chat_group_created_by`),
  CONSTRAINT `carbon_chat_groups_carbon_carbons_entity_pk_fk` FOREIGN KEY (`chat_group_id`) REFERENCES `carbon_carbons` (`entity_pk`),
  CONSTRAINT `carbon_chat_groups_carbon_carbons_entity_pk_fk2` FOREIGN KEY (`chat_group_created_by`) REFERENCES `carbon_carbons` (`entity_pk`)
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;
**/

type GetCustomAndRequiredFields = {}

type GetRequestTableOverrides = {}

// required parameters, optional parameters, parameter type overrides, response, and table names
const Get = restRequest<GetCustomAndRequiredFields, iChat_Groups, GetRequestTableOverrides, iGetC6RestResponse<iChat_Groups>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_groups.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        request.success ??= 'Successfully received chat_groups!'
        request.error ??= 'An unknown issue occurred creating the chat_groups!'
        return request
    },
    responseCallback: (response, _request) => {
        const responseData = response?.data?.rest;
         Voltxt.instance.updateRestfulObjectArrays<iChat_Groups>({
            dataOrCallback: Array.isArray(responseData) ? responseData : [responseData],
            stateKey: "chat_groups",
            uniqueObjectId: C6.chat_groups.PRIMARY_SHORT as (keyof iChat_Groups)[]
        })
    }
});

type PutCustomAndRequiredFields = {}

type PutRequestTableOverrides = {}

export function putState(response: AxiosResponse<iPutC6RestResponse<iChat_Groups>>, request: iAPI<Modify<iChat_Groups, PutRequestTableOverrides>> & PutCustomAndRequiredFields) {
     Voltxt.instance.updateRestfulObjectArrays<iChat_Groups>({
        dataOrCallback: [
            removeInvalidKeys<iChat_Groups>({
                ...request,
                ...response?.data?.rest,
            }, C6.TABLES)
        ],
        stateKey: "chat_groups",
        uniqueObjectId: chat_groups.PRIMARY_SHORT as (keyof iChat_Groups)[]
    })
}

const Put = restRequest<PutCustomAndRequiredFields, iChat_Groups, PutRequestTableOverrides, iPutC6RestResponse<iChat_Groups>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_groups.TABLE_NAME,
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success ??= 'Successfully updated chat_groups data!'
        request.error ??= 'An unknown issue occurred updating the chat_groups data!'
        return request
    },
    responseCallback: putState
});

type PostCustomAndRequiredFields = {}

type PostRequestTableOverrides = {}

export function postState(response: AxiosResponse<iPostC6RestResponse<iChat_Groups>>, request: iAPI<Modify<iChat_Groups, PostRequestTableOverrides>> & PostCustomAndRequiredFields, id: string | number | boolean) {
    if ('number' === typeof id || 'string' === typeof id) {
        if (1 !== chat_groups.PRIMARY_SHORT.length) {
            console.error("C6 received unexpected result's given the primary key length");
        } else {
            request[chat_groups.PRIMARY_SHORT[0]] = id
        }
    }
     Voltxt.instance.updateRestfulObjectArrays<iChat_Groups>({
        dataOrCallback: undefined !== request.dataInsertMultipleRows
            ? request.dataInsertMultipleRows.map((request, index) => {
                return removeInvalidKeys<iChat_Groups>({
                    ...request,
                    ...(index === 0 ? response?.data?.rest : {}),
                }, C6.TABLES)
            })
            : [
                removeInvalidKeys<iChat_Groups>({
                    ...request,
                    ...response?.data?.rest,
                }, C6.TABLES)
            ],
        stateKey: "chat_groups",
        uniqueObjectId: chat_groups.PRIMARY_SHORT as (keyof iChat_Groups)[]
    })
}

const Post = restRequest<PostCustomAndRequiredFields, iChat_Groups, PostRequestTableOverrides, iPostC6RestResponse<iChat_Groups>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_groups.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Successfully created the chat_groups data!'
        request.error ??= 'An unknown issue occurred creating the chat_groups data!'
        return request
    },
    responseCallback: postState
});

type DeleteCustomAndRequiredFields = {}

type DeleteRequestTableOverrides = {}

export function deleteState(_response: AxiosResponse<iDeleteC6RestResponse<iChat_Groups>>, request: iAPI<Modify<iChat_Groups, DeleteRequestTableOverrides>> & DeleteCustomAndRequiredFields) {
     Voltxt.instance.deleteRestfulObjectArrays<iChat_Groups>({
        dataOrCallback: [
            request
        ],
        stateKey: "chat_groups",
        uniqueObjectId: chat_groups.PRIMARY_SHORT as (keyof iChat_Groups)[]
    })
}

const Delete = restRequest<DeleteCustomAndRequiredFields, iChat_Groups, DeleteRequestTableOverrides, iDeleteC6RestResponse<iChat_Groups>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: chat_groups.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success ??= 'Successfully removed the chat_groups data!'
        request.error ??= 'An unknown issue occurred removing the chat_groups data!'
        return request
    },
    responseCallback: deleteState
});

const Chat_Groups = {
    // Export all GET, POST, PUT, DELETE functions for each table
    Get,
    Post,
    Put,
    Delete,
}

export default Chat_Groups;
