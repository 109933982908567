import {globalNavigate} from "@carbonorm/carbonreact";
import Users from "api/rest/Users";
import Popup from "components/Popup/Popup";
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, InputGroup} from 'react-bootstrap';


const Register = () => {
    const [isAuthLoading, setAuthLoading] = useState(false);

    const navigate = useNavigate();

    interface IRegisterForm {
        username: string;
        email: string;
        password: string;
    }

    const register = async (values : IRegisterForm) => {

        try {

            setAuthLoading(true);

            const response = await Users.Post({
                user_username: values.username,
                user_email: values.email,
                user_password: values.password
            });

            console.log('register response', response);

            toast.success('Registration is success');

            navigate('/');

        } catch (error: any) {

            toast.error(error.message || 'Failed');

            setAuthLoading(false);

        }

    };


    const {
        handleChange,
        values,
        handleSubmit,
        touched,
        errors
    } = useFormik(
        {
            initialValues: {
                username: '',
                email: '',
                password: '',
                passwordRetype: ''
            },
            validationSchema: Yup.object({
                username: Yup.string()
                    .min(8, 'Must be at least 8 characters')
                    .max(20, 'Must be less  than 20 characters')
                    .required('Username is required')
                    .test('Unique Username', 'Username already in use', // <- key, message
                        function (_value) {
                            return new Promise((resolve, _reject) => {
                                /*axios.get(`http://localhost:8003/api/u/user/${value}/available`)
                                    .then((res) => {
                                      resolve(true)
                                    })
                                    .catch((error) => {
                                      if (error.response.data.content === "The email has already been taken.") {
                                        resolve(false);
                                      }
                                    })*/

                                resolve(true)

                            })
                        }
                    ),
                email: Yup.string().email('Invalid email address!'),
                password: Yup.string()
                    .min(5, 'Must be 5 characters or more')
                    .max(30, 'Must be 30 characters or less')
                    .required('Required'),
                passwordRetype: Yup.string()
                    .min(5, 'Must be 5 characters or more')
                    .max(30, 'Must be 30 characters or less')
                    .required('Required'),
            }),
            onSubmit: (values) => {
                register(values);
            }
        })

    return (<Popup handleClose={() => {

        globalNavigate('/')

    }}>
        <div className="register-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <b>Affinity</b>
                        <span>Voltex</span>
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">Register new</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="username"
                                    name="username"
                                    type="username"
                                    placeholder="Username"
                                    onChange={handleChange}
                                    value={values.username}
                                    isValid={touched.username && !errors.username}
                                    isInvalid={touched.username && !!errors.username}
                                />
                                {touched.username && errors.username ? (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                ) : (
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <i className="fas fa-user"/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                )}
                            </InputGroup>
                        </div>
                        <div className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="Email (optional)"
                                    onChange={handleChange}
                                    value={values.email}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={touched.email && !!errors.email}
                                />
                                {touched.email && errors.email ? (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                ) : (
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <i className="fas fa-envelope"/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                )}
                            </InputGroup>
                        </div>
                        <div className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    value={values.password}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={touched.password && !!errors.password}
                                />
                                {touched.password && errors.password ? (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                ) : (
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <i className="fas fa-lock"/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                )}
                            </InputGroup>
                        </div>

                        <div className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="passwordRetype"
                                    name="passwordRetype"
                                    type="password"
                                    placeholder="Retype password"
                                    onChange={handleChange}
                                    value={values.passwordRetype}
                                    isValid={touched.passwordRetype && !errors.passwordRetype}
                                    isInvalid={touched.passwordRetype && !!errors.passwordRetype}
                                />

                                {touched.passwordRetype && errors.passwordRetype ? (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.passwordRetype}
                                    </Form.Control.Feedback>
                                ) : (
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <i className="fas fa-lock"/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                )}
                            </InputGroup>
                        </div>

                        <div className="row">
                            <div className="col-7">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"/>
                                        <label
                                            className="form-check-label"><span>I agree to the </span>
                                            <Link to="/">terms</Link></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                <button type="submit" className="btn btn-primary"
                                        disabled={isAuthLoading}>
                                    Register
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="social-auth-links text-center">

                        {/*<button
                            className="btn btn-primary btn-block"
                            onClick={()=>console.log("Facebook")}
                            disabled={true}
                        >
                            <i className="fab fa-facebook mr-2"/>
                            Facebook
                        </button>
                        <button
                            className="btn btn-danger btn-block"
                            onClick={()=>console.log("Google")}
                            disabled={true}
                        >
                            <i className="fab fa-google mr-2"/>
                            Google
                        </button>
                        <button className="btn btn-block" style={{backgroundColor:"#000000"}} onClick={() => {
                            window.location.replace('/bootstrap/twitter/login/')
                        }}>
                            <i className="fab fa-x-twitter mr-2"/>
                            X
                        </button>
                        <br/>
                        <button className="btn btn-block" style={{backgroundColor:"#5865F2"}} onClick={() => {
                            window.location.replace('/bootstrap/discord/login/')
                        }}>
                            <i className="fab fa-discord mr-2"/>
                            Discord
                        </button>*/}
                    </div>
                    <Link to="/login" className="text-center">
                        already have an account?
                    </Link>
                </div>
            </div>
        </div></Popup>
    );
};

export default Register;
