import {
    iPostC6RestResponse,
    restRequest,
    GET,
    POST,
    PUT,
    DELETE,
    iDeleteC6RestResponse,
    iGetC6RestResponse,
    iPutC6RestResponse,
    removeInvalidKeys,
    iAPI,
    Modify
} from "@carbonorm/carbonnode";
import {AxiosResponse} from "axios";
import {C6, subscription, iSubscription, RestTableNames} from "./C6";
import Voltxt from "Voltxt";

/**
CREATE TABLE `carbon_subscription` (
  `subscription_id` binary(16) NOT NULL,
  `subscription_to_entity_id` binary(16) DEFAULT NULL,
  `subscription_for_entity_id` binary(16) DEFAULT NULL,
  `subscription_price` varchar(16) DEFAULT NULL,
  `subscription_start_date` datetime NOT NULL DEFAULT CURRENT_TIMESTAMP,
  `subscription_end_date` datetime DEFAULT NULL,
  `subscription_transaction_id` varchar(255) DEFAULT NULL,
  PRIMARY KEY (`subscription_id`),
  KEY `carbon_subscription_carbon_carbons_entity_pk_fk2` (`subscription_to_entity_id`),
  KEY `carbon_subscription_carbon_carbons_entity_pk_fk3` (`subscription_for_entity_id`),
  CONSTRAINT `carbon_subscription_carbon_carbons_entity_pk_fk` FOREIGN KEY (`subscription_id`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `carbon_subscription_carbon_carbons_entity_pk_fk2` FOREIGN KEY (`subscription_to_entity_id`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `carbon_subscription_carbon_carbons_entity_pk_fk3` FOREIGN KEY (`subscription_for_entity_id`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;
**/

type GetCustomAndRequiredFields = {}

type GetRequestTableOverrides = {}

// required parameters, optional parameters, parameter type overrides, response, and table names
const Get = restRequest<GetCustomAndRequiredFields, iSubscription, GetRequestTableOverrides, iGetC6RestResponse<iSubscription>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: subscription.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        request.success ??= 'Successfully received subscription!'
        request.error ??= 'An unknown issue occurred creating the subscription!'
        return request
    },
    responseCallback: (response, _request) => {
        const responseData = response?.data?.rest;
         Voltxt.instance.updateRestfulObjectArrays<iSubscription>({
            dataOrCallback: Array.isArray(responseData) ? responseData : [responseData],
            stateKey: "subscription",
            uniqueObjectId: C6.subscription.PRIMARY_SHORT as (keyof iSubscription)[]
        })
    }
});

type PutCustomAndRequiredFields = {}

type PutRequestTableOverrides = {}

export function putState(response: AxiosResponse<iPutC6RestResponse<iSubscription>>, request: iAPI<Modify<iSubscription, PutRequestTableOverrides>> & PutCustomAndRequiredFields) {
     Voltxt.instance.updateRestfulObjectArrays<iSubscription>({
        dataOrCallback: [
            removeInvalidKeys<iSubscription>({
                ...request,
                ...response?.data?.rest,
            }, C6.TABLES)
        ],
        stateKey: "subscription",
        uniqueObjectId: subscription.PRIMARY_SHORT as (keyof iSubscription)[]
    })
}

const Put = restRequest<PutCustomAndRequiredFields, iSubscription, PutRequestTableOverrides, iPutC6RestResponse<iSubscription>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: subscription.TABLE_NAME,
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success ??= 'Successfully updated subscription data!'
        request.error ??= 'An unknown issue occurred updating the subscription data!'
        return request
    },
    responseCallback: putState
});

type PostCustomAndRequiredFields = {}

type PostRequestTableOverrides = {}

export function postState(response: AxiosResponse<iPostC6RestResponse<iSubscription>>, request: iAPI<Modify<iSubscription, PostRequestTableOverrides>> & PostCustomAndRequiredFields, id: string | number | boolean) {
    if ('number' === typeof id || 'string' === typeof id) {
        if (1 !== subscription.PRIMARY_SHORT.length) {
            console.error("C6 received unexpected result's given the primary key length");
        } else {
            request[subscription.PRIMARY_SHORT[0]] = id
        }
    }
     Voltxt.instance.updateRestfulObjectArrays<iSubscription>({
        dataOrCallback: undefined !== request.dataInsertMultipleRows
            ? request.dataInsertMultipleRows.map((request, index) => {
                return removeInvalidKeys<iSubscription>({
                    ...request,
                    ...(index === 0 ? response?.data?.rest : {}),
                }, C6.TABLES)
            })
            : [
                removeInvalidKeys<iSubscription>({
                    ...request,
                    ...response?.data?.rest,
                }, C6.TABLES)
            ],
        stateKey: "subscription",
        uniqueObjectId: subscription.PRIMARY_SHORT as (keyof iSubscription)[]
    })
}

const Post = restRequest<PostCustomAndRequiredFields, iSubscription, PostRequestTableOverrides, iPostC6RestResponse<iSubscription>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: subscription.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Successfully created the subscription data!'
        request.error ??= 'An unknown issue occurred creating the subscription data!'
        return request
    },
    responseCallback: postState
});

type DeleteCustomAndRequiredFields = {}

type DeleteRequestTableOverrides = {}

export function deleteState(_response: AxiosResponse<iDeleteC6RestResponse<iSubscription>>, request: iAPI<Modify<iSubscription, DeleteRequestTableOverrides>> & DeleteCustomAndRequiredFields) {
     Voltxt.instance.deleteRestfulObjectArrays<iSubscription>({
        dataOrCallback: [
            request
        ],
        stateKey: "subscription",
        uniqueObjectId: subscription.PRIMARY_SHORT as (keyof iSubscription)[]
    })
}

const Delete = restRequest<DeleteCustomAndRequiredFields, iSubscription, DeleteRequestTableOverrides, iDeleteC6RestResponse<iSubscription>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: subscription.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success ??= 'Successfully removed the subscription data!'
        request.error ??= 'An unknown issue occurred removing the subscription data!'
        return request
    },
    responseCallback: deleteState
});

const Subscription = {
    // Export all GET, POST, PUT, DELETE functions for each table
    Get,
    Post,
    Put,
    Delete,
}

export default Subscription;
