// import {convertUTCToLocal} from "api/hoc/time";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import {iProjects, iUsers} from "api/rest/C6";
import Users from "api/rest/Users";
import {toast} from "react-toastify";
import defaultAvatar from "../../assets/img/defaultAvatar.svg";
import {Link} from "react-router-dom";
import Voltxt from "Voltxt";


export default function UserListItem({user, key}: { user: iUsers, key: number }) {

    const {projects} = Voltxt.instance.state;

    const userProjects = projects?.filter((project: iProjects) => project.project_leader === user.user_id) || [];

    const updateUser = (user: iUsers) => Users.Put(user)

    const whoami = getCurrentLoggedInUser()

    return <tr key={key}>
        <td>
            <ul className="d-flex align-items-center">
                <li className="list-inline-item">
                    <img alt="Avatar" className="table-avatar" src={user.user_profile_pic ?? defaultAvatar}/>
                </li>

                <br/>

                <li className="list-inline-item ml-2">
                    <Link to={`/profile/${user.user_id}`} style={{cursor: 'pointer'}}>
                        {user?.user_username}
                    </Link>
                </li>
            </ul>
        </td>


        {(() => {
            if (!whoami?.user_is_administrator) {
                return
            }

            const isMySelf = () => {
                if (whoami?.user_id === user.user_id) {
                    toast.info('You cannot change your own permissions for the following roles (Moderator, Administrator, Disabled)')
                    return true
                }
                return false
            }

            return <>
                <td className={'List-inline'}>
                    <div className="form-group">
                        <div
                            className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                            <input type="checkbox" checked={user.user_is_disabled === 1}
                                   onClick={() => isMySelf() || updateUser({
                                       user_is_disabled: user.user_is_disabled === 1 ? 0 : 1,
                                       user_id: user.user_id,
                                   })} className="custom-control-input" id={"customSwitchDisabled" + user.user_id}/>
                            <label className="custom-control-label"
                                   htmlFor={"customSwitchDisabled" + user.user_id}>Disabled</label>
                        </div>
                    </div>
                </td>
                <td className={'List-inline'}>
                    <div className="form-group">
                        <div
                            className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                            <input type="checkbox" checked={user.user_is_caller === 1}
                                   onClick={() => updateUser({
                                       user_is_caller: user.user_is_caller === 1 ? 0 : 1,
                                       user_id: user.user_id,
                                   })} className="custom-control-input" id={"customSwitchCaller" + user.user_id}/>
                            <label className="custom-control-label"
                                   htmlFor={"customSwitchCaller" + user.user_id}>Caller</label>
                        </div>
                    </div>
                </td>
                <td className={'List-inline'}>
                    <div className="form-group">
                        <div
                            className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                            <input type="checkbox" checked={user.user_is_moderator === 1}
                                   onClick={() => isMySelf() || updateUser({
                                       user_is_moderator: user.user_is_moderator === 1 ? 0 : 1,
                                       user_id: user.user_id,
                                   })} className="custom-control-input"
                                   id={"customSwitchModerator" + user.user_id}/>
                            <label className="custom-control-label"
                                   htmlFor={"customSwitchModerator" + user.user_id}>Moderator</label>
                        </div>
                    </div>
                </td>
                <td className={'List-inline'}>
                    <div className="form-group">
                        <div
                            className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                            <input type="checkbox" checked={user.user_is_administrator === 1}
                                   onClick={() => isMySelf() || updateUser({
                                       user_is_administrator: user.user_is_administrator === 1 ? 0 : 1,
                                       user_id: user.user_id,
                                   })} className="custom-control-input"
                                   id={"customSwitchAdministrator" + user.user_id}/>
                            <label className="custom-control-label"
                                   htmlFor={"customSwitchAdministrator" + user.user_id}>Administrator</label>
                        </div>
                    </div>
                </td>
            </>

        })()}


        <td className="list-inline">
            {userProjects.length > 0 ? (
                <div className="d-flex">
                    {userProjects.map((project: iProjects) => (
                        <li key={project.project_id}>
                            <Link to={`/project/${project.project_id}`} style={{cursor: 'pointer'}}
                                  className="btn btn-primary btn-sm mr-3">
                                {project.project_name} {/* Replace with the actual project property */}
                            </Link>
                        </li>
                    ))}
                </div>
            ) : (
                "No projects available"
            )}
        </td>


    </tr>
}