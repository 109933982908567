import {
    iPostC6RestResponse,
    restRequest,
    GET,
    POST,
    PUT,
    DELETE,
    iDeleteC6RestResponse,
    iGetC6RestResponse,
    iPutC6RestResponse,
    removeInvalidKeys,
    iAPI,
    Modify
} from "@carbonorm/carbonnode";
import {AxiosResponse} from "axios";
import {C6, projects, iProjects, RestTableNames} from "./C6";
import Voltxt from "Voltxt";

/**
CREATE TABLE `carbon_projects` (
  `project_id` binary(16) NOT NULL,
  `project_advertisement_image` binary(16) DEFAULT NULL,
  `project_overview_image` binary(16) DEFAULT NULL,
  `project_name` varchar(255) DEFAULT NULL,
  `project_start_date` datetime DEFAULT NULL,
  `project_end_date` datetime DEFAULT NULL,
  `project_description` text,
  `project_leader` binary(16) DEFAULT NULL,
  `project_cost` float DEFAULT NULL,
  `project_overview_top` varchar(255) DEFAULT NULL COMMENT 'ie estimated monthly return',
  `project_overview_bottom` varchar(255) DEFAULT NULL,
  `project_ends` tinyint DEFAULT NULL,
  PRIMARY KEY (`project_id`),
  KEY `carbon_projects_carbon_carbons_entity_pk_fk2` (`project_leader`),
  KEY `carbon_projects_carbon_carbons_entity_pk_fk_2` (`project_advertisement_image`),
  KEY `carbon_projects_carbon_carbons_entity_pk_fk_3` (`project_overview_image`),
  CONSTRAINT `carbon_projects_carbon_carbons_entity_pk_fk` FOREIGN KEY (`project_id`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `carbon_projects_carbon_carbons_entity_pk_fk2` FOREIGN KEY (`project_leader`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `carbon_projects_carbon_carbons_entity_pk_fk_2` FOREIGN KEY (`project_advertisement_image`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE SET NULL ON UPDATE CASCADE,
  CONSTRAINT `carbon_projects_carbon_carbons_entity_pk_fk_3` FOREIGN KEY (`project_overview_image`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE SET NULL ON UPDATE CASCADE
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;
**/

type GetCustomAndRequiredFields = {}

type GetRequestTableOverrides = {}

// required parameters, optional parameters, parameter type overrides, response, and table names
const Get = restRequest<GetCustomAndRequiredFields, iProjects, GetRequestTableOverrides, iGetC6RestResponse<iProjects>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: projects.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        request.success ??= 'Successfully received projects!'
        request.error ??= 'An unknown issue occurred creating the projects!'
        return request
    },
    responseCallback: (response, _request) => {
        const responseData = response?.data?.rest;
         Voltxt.instance.updateRestfulObjectArrays<iProjects>({
            dataOrCallback: Array.isArray(responseData) ? responseData : [responseData],
            stateKey: "projects",
            uniqueObjectId: C6.projects.PRIMARY_SHORT as (keyof iProjects)[]
        })
    }
});

type PutCustomAndRequiredFields = {}

type PutRequestTableOverrides = {}

export function putState(response: AxiosResponse<iPutC6RestResponse<iProjects>>, request: iAPI<Modify<iProjects, PutRequestTableOverrides>> & PutCustomAndRequiredFields) {
     Voltxt.instance.updateRestfulObjectArrays<iProjects>({
        dataOrCallback: [
            removeInvalidKeys<iProjects>({
                ...request,
                ...response?.data?.rest,
            }, C6.TABLES)
        ],
        stateKey: "projects",
        uniqueObjectId: projects.PRIMARY_SHORT as (keyof iProjects)[]
    })
}

const Put = restRequest<PutCustomAndRequiredFields, iProjects, PutRequestTableOverrides, iPutC6RestResponse<iProjects>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: projects.TABLE_NAME,
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success ??= 'Successfully updated projects data!'
        request.error ??= 'An unknown issue occurred updating the projects data!'
        return request
    },
    responseCallback: putState
});

type PostCustomAndRequiredFields = {}

type PostRequestTableOverrides = {}

export function postState(response: AxiosResponse<iPostC6RestResponse<iProjects>>, request: iAPI<Modify<iProjects, PostRequestTableOverrides>> & PostCustomAndRequiredFields, id: string | number | boolean) {
    if ('number' === typeof id || 'string' === typeof id) {
        if (1 !== projects.PRIMARY_SHORT.length) {
            console.error("C6 received unexpected result's given the primary key length");
        } else {
            request[projects.PRIMARY_SHORT[0]] = id
        }
    }
     Voltxt.instance.updateRestfulObjectArrays<iProjects>({
        dataOrCallback: undefined !== request.dataInsertMultipleRows
            ? request.dataInsertMultipleRows.map((request, index) => {
                return removeInvalidKeys<iProjects>({
                    ...request,
                    ...(index === 0 ? response?.data?.rest : {}),
                }, C6.TABLES)
            })
            : [
                removeInvalidKeys<iProjects>({
                    ...request,
                    ...response?.data?.rest,
                }, C6.TABLES)
            ],
        stateKey: "projects",
        uniqueObjectId: projects.PRIMARY_SHORT as (keyof iProjects)[]
    })
}

const Post = restRequest<PostCustomAndRequiredFields, iProjects, PostRequestTableOverrides, iPostC6RestResponse<iProjects>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: projects.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Successfully created the projects data!'
        request.error ??= 'An unknown issue occurred creating the projects data!'
        return request
    },
    responseCallback: postState
});

type DeleteCustomAndRequiredFields = {}

type DeleteRequestTableOverrides = {}

export function deleteState(_response: AxiosResponse<iDeleteC6RestResponse<iProjects>>, request: iAPI<Modify<iProjects, DeleteRequestTableOverrides>> & DeleteCustomAndRequiredFields) {
     Voltxt.instance.deleteRestfulObjectArrays<iProjects>({
        dataOrCallback: [
            request
        ],
        stateKey: "projects",
        uniqueObjectId: projects.PRIMARY_SHORT as (keyof iProjects)[]
    })
}

const Delete = restRequest<DeleteCustomAndRequiredFields, iProjects, DeleteRequestTableOverrides, iDeleteC6RestResponse<iProjects>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: projects.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success ??= 'Successfully removed the projects data!'
        request.error ??= 'An unknown issue occurred removing the projects data!'
        return request
    },
    responseCallback: deleteState
});

const Projects = {
    // Export all GET, POST, PUT, DELETE functions for each table
    Get,
    Post,
    Put,
    Delete,
}

export default Projects;
