import {iUsers} from "api/rest/C6";
import Voltxt from "Voltxt";

export default function getCurrentLoggedInUser() : (iUsers) | undefined {

    const bootstrap = Voltxt.instance;

    // @ts-ignore
    return bootstrap.state.users?.find(user => user.user_id === bootstrap.state.user_id)

}