//import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

// Import SVG icons
import framedDiscord from 'assets/img/framedDiscord.svg';
import framedEmail from 'assets/img/framedEmail.svg';
import framedFacebook from 'assets/img/framedFacebook.svg';
import framedInsta from 'assets/img/framedInsta.svg';
import framedSMS from 'assets/img/framedSMS.svg';
import framedTelegram from 'assets/img/framedTelegram.svg';
import framedTwitter from 'assets/img/framedTwitter.svg';

// @ts-ignore
import packageJSON from '../../../../package.json';
import styles from 'style.module.scss';

const Footer = () => {

  return (
    <footer className={styles.footer}>
      <strong className={styles.copyright}>
        <span>Copyright © {DateTime.now().toFormat('y')} </span>
        <a href="https://voltxt.com/" target="_blank" rel="noopener noreferrer">
          Voltex LLC
        </a>
        <span>.</span>
      </strong>

      {/* Centered SVG Icon Links */}
      <div className={styles.iconLinks}>
        <Link to="/discord">
          <img src={framedDiscord} alt="Discord" />
        </Link>
        <Link to="/email">
          <img src={framedEmail} alt="Email" />
        </Link>
        <Link to="/facebook">
          <img src={framedFacebook} alt="Facebook" />
        </Link>
        <Link to="/instagram">
          <img src={framedInsta} alt="Instagram" />
        </Link>
        <Link to="/sms">
          <img src={framedSMS} alt="SMS" />
        </Link>
        <Link to="/telegram">
          <img src={framedTelegram} alt="Telegram" />
        </Link>
        <Link to="/twitter">
          <img src={framedTwitter} alt="Twitter" />
        </Link>
      </div>

      <div className={styles.version}>
        <b>Version</b>
        <span>&nbsp;{packageJSON.version}</span>
      </div>
    </footer>
  );
};

export default Footer;