import Voltxt from "Voltxt";
import {convertUTCToLocal} from "api/hoc/time";
import {iProjects} from "api/rest/C6";
import defaultAvatar from "assets/img/defaultAvatar.svg";
import moment from "moment";
import {Link} from "react-router-dom";


export default function ProjectListItem({project}: { project: iProjects}) {


    const projectLeader = Voltxt.instance.state.users?.find(user => user.user_id === project?.project_leader);

    let time = moment().unix()

    let timeStart = moment(project?.project_start_date).unix() - time

    let timeEnd = moment(project?.project_end_date).unix() - time

    let percentComplete = 0

    console.log('timeStart', timeStart, 'timeEnd', timeEnd, 'percentComplete', percentComplete)

    if (0 > timeEnd) {

        percentComplete = 100

    } else if (0 < timeStart) {

        percentComplete = 0

    } else {

        let timeElapsed = Math.abs(timeStart),
            totalTime = timeEnd + timeElapsed;


        if (0 !== totalTime) {

            percentComplete = Math.round(timeElapsed / (timeEnd + timeElapsed) * 100)

        }

    }

    return <tr>

        <td>
            <Link to={`/projects/${project.project_id}`} style={{ cursor: 'pointer' }}>
                {project?.project_name}
            </Link>
            <br/>
            <small>
                Started {convertUTCToLocal(project?.project_start_date)}
            </small>
        </td>
        <td>
            <ul className="list-inline">
                <li className="list-inline-item">
                    <img alt="Avatar" className="table-avatar" src={projectLeader?.user_profile_pic ?? defaultAvatar}/>
                </li>
            </ul>
        </td>
        <td>
            <div>
                {project.project_description}
            </div>
        </td>
        <td className="project_progress">
                <div className="progress progress-sm">
                    <div className="progress-bar bg-green" role="progressbar"
                         aria-valuenow={percentComplete}
                         aria-valuemin={0}
                         aria-valuemax={100} style={{width: percentComplete + "%"}}>
                    </div>
                </div>
                <small>
                    {percentComplete}% Complete
                </small>
        </td>

        <td className="project-actions text-right">
            <Link to={`/projects/${project.project_id}`} style={{ cursor: 'pointer' }} className="btn btn-primary btn-sm">
                <i className="fas fa-folder">
                </i>
                View
            </Link>
            {/*<a className="btn btn-info btn-sm" href="#">
                <i className="fas fa-pencil-alt">
                </i>
                Edit
            </a>
            <a className="btn btn-danger btn-sm" href="#">
                <i className="fas fa-trash">
                </i>
                Delete
            </a>*/}
        </td>
    </tr>
}