import ActivityTab from "pages/profile/ActivityTab";
import SettingsTab from "pages/profile/SettingsTab";
import TimelineTab from "pages/profile/TimelineTab";
import {useState} from "react";
import isLocal from "variables/isLocal";


export default function ProfileTabs(){
    const [activeTab, setActiveTab] = useState('ACTIVITY');
    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return <div className="card">
        <div className="card-header p-2">
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <button
                        type="button"
                        className={`nav-link ${
                            activeTab === 'ACTIVITY' ? 'active' : ''
                        }`}
                        onClick={() => toggle('ACTIVITY')}
                    >
                        Activity
                    </button>
                </li>
                {isLocal && <li className="nav-item">
                    <button
                        type="button"
                        className={`nav-link ${
                            activeTab === 'TIMELINE' ? 'active' : ''
                        }`}
                        onClick={() => toggle('TIMELINE')}
                    >
                        Timeline
                    </button>
                </li>}
                <li className="nav-item">
                    <button
                        type="button"
                        className={`nav-link ${
                            activeTab === 'SETTINGS' ? 'active' : ''
                        }`}
                        onClick={() => toggle('SETTINGS')}
                    >
                        Settings
                    </button>
                </li>
            </ul>
        </div>
        <div className="card-body">
            <div className="tab-content">
                <ActivityTab isActive={activeTab === 'ACTIVITY'}/>
                <TimelineTab isActive={activeTab === 'TIMELINE'}/>
                <SettingsTab isActive={activeTab === 'SETTINGS'}/>
            </div>
        </div>
    </div>
}