import moment from "moment-timezone";
export const momentGeneralStringFormatForMysql =  "YYYY-MM-DD HH:mm:ss";
export const CoordinatedUniversalTime = "UTC";

export function convertUTCToLocal(date: string|undefined|null): string {
    return undefined === date || null === date ? '' : moment.tz(date, momentGeneralStringFormatForMysql, CoordinatedUniversalTime)
        .utc().utcOffset(moment().utcOffset()).format('LLL') + ' ' + moment.tz(moment.tz.guess()).zoneAbbr()
}

export function convertLocalToUTC(date: string | undefined | null, localTimezone?: string|undefined): string {
    return undefined === date || null === date  ? '' : moment.tz(date, momentGeneralStringFormatForMysql, localTimezone ?? moment.tz.guess())
        .utc().utcOffset(moment().utcOffset()).format('LLL') + ' ' + CoordinatedUniversalTime
}

export function convertDateToMySqlFormat(date: string | undefined): string {
    return undefined === date ? '' : moment(date).utc().utcOffset(moment().utcOffset()).format(momentGeneralStringFormatForMysql)
}

export function unixTimeUntil(date: string): number {
    return moment.tz(date, momentGeneralStringFormatForMysql, CoordinatedUniversalTime)
        .utc().utcOffset(moment().utcOffset()).unix() - moment().utc().utcOffset(moment().utcOffset()).unix()
}