import MarkdownEditor from "@uiw/react-markdown-editor";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import Posts from "api/rest/Posts";
import Voltxt from "Voltxt";
import NewProject from "pages/projects/NewProject";
import {useState} from "react";
import {Link} from "react-router-dom";


export default function NewPost () {

    const whoami = getCurrentLoggedInUser()

    const projects = Voltxt.instance.state.projects?.filter((project) => project.project_leader === whoami?.user_id);

    const [newPost, setNewPost] = useState('\n\n\n');

    const [newPostProjectId, setNewPostProjectId] = useState<string | undefined>(projects?.[0]?.project_id);

    const postPost = () => {
        Posts.Post({
            post_author: whoami?.user_id,
            post_data: newPost,
            post_project_id: newPostProjectId
        })
        setNewPost('');
    };


    if (undefined === projects) {

        return <div className="card">
            <div className="card-header">
                <h3 className="card-title">Loading your projects.</h3>
            </div>
        </div>

    }


    if (1 > projects?.length) {

        return <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Start a new project to add posts.</h3>
                </div>
            </div>
            <NewProject/>
        </>

    }


    return <div className="card">
        <div className="card-header">
            <h3 className="card-title">New <Link to={'/markdown'}>Markdown</Link> Post </h3>
            <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse"
                        title="Collapse">
                    <i className="fas fa-check"></i>
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove"
                        title="Remove">
                    <i className="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div className="card-body" style={{color: "white", fill: "white"}}>
            <div className="App" data-color-mode="dark" style={{
                zIndex: '1010', position: 'relative', height: '100%', width: '100%',
            }}>
                <MarkdownEditor
                    value={newPost}
                    onChange={(value) => {
                        //setMarkdownVal(value);
                        setNewPost(value);
                    }}
                />
            </div>
        </div>
        <div className="card-footer">
            <div className="float-left">
                <div className="float-right">
                    <select className="custom-select form-control-border"
                            onChange={(e) => setNewPostProjectId(e.target.value)}
                            id="exampleSelectBorder">
                        {projects?.map((project, index) => {
                            return <option key={index} value={project.project_id}>{project.project_name}</option>
                        })}
                    </select>
                </div>
            </div>

            <div className="float-right">
                <div onClick={postPost} className="btn btn-sm btn-success">Post</div>
            </div>
        </div>
    </div>

}