import { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import logo from 'assets/img/solana_logo.png';
import 'style.module.scss';
import WalletCard from 'components/wallet/WalletCard';

const Wallet = () => {
  
  const getRandomBalance = (min, max) => {
    return parseFloat((Math.random() * (max - min) + min).toFixed(2));
  };

  const [todaysBalance] = useState(getRandomBalance(100, 100000));
  const [yesterdaysBalance] = useState(getRandomBalance(100, 100000));
  let todaysProfit = todaysBalance - yesterdaysBalance;

  const [showWalletCard, setShowWalletCard] = useState(false);
  const [wallets, setWallets] = useState<string[]>([]);

  const handleOpen = () => setShowWalletCard(true);
  const handleClose = () => {
    console.log("I'm in Wallet's handleClose");
    setShowWalletCard(false);
  }

  const addWallet = () => {
    console.log("I'm In Add Wallet");
    setWallets([...wallets, `Wallet ${wallets.length + 1}`]);
    handleClose();  // Optional: Close the modal upon adding a wallet
  };

  const removeWallet = (index: number) => {
    setWallets(wallets.filter((_, i) => i !== index));
  };

  const percentageChange = ((todaysBalance - yesterdaysBalance) / yesterdaysBalance) * 100;
  const isPositive = percentageChange > 0;
  const isNeutral = percentageChange === 0;

  const items = Array.from({ length: 35 }, (_, index) => index + 1);

  return (
    <div className="walletPageContainer">
      <div className="walletContainer">
        <div className="currentBalance">
          <div className="balanceHeader">
            <h1>Current Balance</h1>
            <div className="percentageChange" style={{ color: isNeutral ? 'grey' : isPositive ? 'green' : 'red' }}>
              {isNeutral ? '' : isPositive ? '▲' : '▼'} {Math.abs(+percentageChange.toFixed(2))}%
            </div>
          </div>
          <h2>${todaysBalance.toFixed(2)}</h2>
          <p>+ ${todaysProfit.toFixed(2)} Today's Profit</p>
        </div>
        <div className="wallets">
          <div className="walletHeader">
            <h3>Solana Wallets</h3>
            {/*<Button variant="outline-light" className="defaultAddButton" onClick={addWallet}>*/}
            <Button variant="outline-light" className="defaultAddButton" onClick={handleOpen}>
              Add New Wallet
            </Button>
            <WalletCard show={showWalletCard} handleClose={handleClose} addWallet={addWallet} />
          </div>
          <div className="walletList row">
            {wallets.length > 0 ? (
              wallets.map((wallet, index) => (
                <Card key={index} className="col-12 walletPageCard">
                  <Card.Body>
                    <div className="walletDetails">
                      <div className="walletInfo">
                        <img src={logo} alt="Solana Logo" className="solanaLogo"/>
                        <span>{wallet} | Details</span>
                      </div>
                      <Button className="defaultRemoveButton" onClick={() => removeWallet(index)}>
                        Remove Wallet
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>No wallets added yet</p>
            )}
          </div>
        </div>
        {/* New Grid Section Below */}
        <div className="sectionContainer">
          <h2 className="sectionHeader">Section Header</h2>
          <div className="gridContainer">
            {items.map((item) => (
              <div key={item} className="gridItem"></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
