import React from 'react';
import Routing from "Routing";
import Voltxt from "Voltxt";
import ReactDOM from 'react-dom/client';
import {eRouterType} from "@carbonorm/carbonreact";


import "bootstrap/dist/js/bootstrap.js";
import "admin-lte/dist/js/adminlte.min.js";

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root')!;

const root = ReactDOM.createRoot(rootElement);

rootElement.classList.add('dark-mode');

root.render(
    <React.StrictMode>
        <Voltxt routerType={eRouterType.HashRouter}>
            <Routing/>
        </Voltxt>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({});
