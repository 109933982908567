import Voltxt from "Voltxt";

export interface SearchState {
    search: string;
}

export const initialSearchState: SearchState = {
    search: ''
}

export const setSearch = (search: string) => Voltxt.instance.setState({
    search: search
})