import Voltxt from "Voltxt";
import newImage from "api/hoc/newImage";
import {iProjects} from "api/rest/C6";
import defaultImg from "assets/img/voltexVFullColor.svg"
import moment from "moment";
import {PropsWithChildren} from "react";

export default function Advertisement({project}: PropsWithChildren<{
    project: iProjects,
}>) {

    const {users, photos, user_id} = Voltxt.instance.state;

    const projectBy = users?.find(user => user.user_id === project?.project_leader);

    const isMyProject = projectBy?.user_id === user_id;

    const projectPhotos = photos?.filter(image => image.photo_description === 'Advertisement' && image?.user_id === project?.project_leader);

    const advertisementPicture = projectPhotos
        ?.sort((a, b) => parseInt(moment(a.photo_upload_date).format('YYYYMMDD')) - parseInt(moment(b.photo_upload_date).format('YYYYMMDD')))
        .pop();

    return <div className="card mb-2 bg-gradient-dark">
        <img className="card-img-top" src={advertisementPicture?.photo_src ?? defaultImg} alt="default project advertisement"/>
        <div className="card-img-overlay d-flex flex-column justify-content-end"
             onClick={() => {

                 console.log(isMyProject, project?.project_id);


                 if (isMyProject && project?.project_id) {
                     newImage({
                         parent_id: project.project_id,
                         description: 'Advertisement',
                     })()
                 }
             }}>
            <h5 className="card-title text-primary text-white">{project?.project_name}</h5>
            <p className="card-text text-white pb-2 pt-1">{project?.project_description}</p>
            <a href="#" className="text-white">Last update 2 mins ago</a>
        </div>
    </div>
};

