import Voltxt from "Voltxt";
import {
    addWindowClass,
    calculateWindowSize,
    removeWindowClass
} from 'utils/helpers';
import {
    NAVBAR_DARK_VARIANTS,
    NAVBAR_LIGHT_VARIANTS,
    SIDEBAR_DARK_SKINS,
    SIDEBAR_LIGHT_SKINS
} from 'utils/themes';

export interface UiState {
    screenSize: string;
    menuSidebarCollapsed: boolean;
    controlSidebarCollapsed: boolean;
    darkMode: boolean;
    headerBorder: boolean;
    headerFixed: boolean;
    footerFixed: boolean;
    layoutBoxed: boolean;
    layoutFixed: boolean;
    menuChildIndent: boolean;
    navbarVariant: string;
    sidebarSkin: string;
}

export const initialUiState: UiState = {
    screenSize: calculateWindowSize(window.innerWidth),
    darkMode: true,
    navbarVariant: NAVBAR_DARK_VARIANTS[0].value,
    sidebarSkin: SIDEBAR_DARK_SKINS[0].value,
    menuSidebarCollapsed: true,
    controlSidebarCollapsed: true,
    headerBorder: false,
    headerFixed: false,
    footerFixed: true,
    layoutBoxed: false,
    menuChildIndent: false,
    layoutFixed: false
};

addWindowClass('layout-footer-fixed');


export const toggleSidebarMenu = () => {
    Voltxt.instance.setState((previousState)=> ({
        menuSidebarCollapsed: !previousState.menuSidebarCollapsed
    }))
}

export const toggleControlSidebar = () => {
    Voltxt.instance.setState((previousState)=> ({
        controlSidebarCollapsed: !previousState.controlSidebarCollapsed
    }))
}
export const toggleHeaderBorder = () => {
    Voltxt.instance.setState((previousState)=> ({
        headerBorder: !previousState.headerBorder
    }))
}
export const toggleHeaderFixed = () => {

    const instance = Voltxt.instance;

    instance.setState((previousState)=> ({
        headerFixed: !previousState.headerFixed
    }), () => {

        if (instance.state.headerFixed) {
            addWindowClass('layout-navbar-fixed');
        } else {
            removeWindowClass('layout-navbar-fixed');
        }

    })

};

export const toggleFooterFixed = () => {

    const instance = Voltxt.instance;

    instance.setState((previousState)=> ({
        footerFixed: !previousState.footerFixed
    }), () => {

        if (instance.state.footerFixed) {
            addWindowClass('layout-footer-fixed');
        } else {
            removeWindowClass('layout-footer-fixed');
        }

    })

}

export const toggleLayoutBoxed = () => {

    const instance = Voltxt.instance;

    instance.setState((previousState)=> ({
        layoutBoxed: !previousState.layoutBoxed
    }), () => {

        if (instance.state.layoutBoxed) {
            addWindowClass('layout-boxed');
        } else {
            removeWindowClass('layout-boxed');
        }

    })
}

export const toggleLayoutFixed = (state) => {

    const instance = Voltxt.instance;

    instance.setState((previousState)=> ({
        layoutFixed: !previousState.layoutFixed
    }), () => {

        if (state.layoutFixed) {
            removeWindowClass('layout-fixed');
        } else {
            addWindowClass('layout-fixed');
        }

    })

}

export const toggleMenuChildIndent = () => {
    Voltxt.instance.setState((previousState)=> ({
        menuChildIndent: !previousState.menuChildIndent
    }))
}

export const toggleDarkMode = () => {
    Voltxt.instance.setState((previousState)=> ({
        darkMode: !previousState.darkMode,
        navbarVariant: previousState.darkMode ?  NAVBAR_LIGHT_VARIANTS[0].value : NAVBAR_DARK_VARIANTS[0].value,
        sidebarSkin: previousState.darkMode ?  SIDEBAR_LIGHT_SKINS[0].value : SIDEBAR_DARK_SKINS[0].value
    }), () => {
        if (Voltxt.instance.state.darkMode) {
            addWindowClass('dark-mode');
        } else {
            removeWindowClass('dark-mode');
        }
    })

}

export const setNavbarVariant = (newValue) => {
    Voltxt.instance.setState((previousState)=> ({
        navbarVariant: newValue || (previousState.darkMode ? NAVBAR_DARK_VARIANTS[0].value : NAVBAR_LIGHT_VARIANTS[0].value)
    }))
}

export const setSidebarSkin = (newValue) => {
    Voltxt.instance.setState((previousState)=> ({
        sidebarSkin: newValue || (previousState.darkMode ? SIDEBAR_DARK_SKINS[0].value : SIDEBAR_LIGHT_SKINS[0].value)
    }))
}

export const setWindowSize = (state, {payload}) => {
    state.screenSize = payload;
}

