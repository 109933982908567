// import {convertUTCToLocal} from "api/hoc/time";
import {watchInputElementChangeEvent} from "@carbonorm/carbonreact";
import Voltxt from "Voltxt";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import {iCaller_Requests} from "api/rest/C6";
import Caller_Requests from "api/rest/Caller_Requests";
import Users from "api/rest/Users";
// import Users from "api/rest/Users";
import defaultAvatar from "assets/img/defaultAvatar.svg";
import {useState} from "react";
import {Link} from "react-router-dom";

// import Affinity from "Affinity";

enum eInputStatus {
    notChanged = -2,
    waiting = -1,
    success = 1,
    failure = 0,
}

export default function CallerRequestListItem({caller_request}: { caller_request: iCaller_Requests }) {

    const {users} = Voltxt.instance.state;

    const [inputStatus, setInputStatus] = useState<eInputStatus>(eInputStatus.notChanged);

    const [callerRequestResponse, setCallerRequestResponse] = useState<string>(caller_request.caller_request_response ?? '');

    const user = users?.find(user => user.user_id === caller_request.caller_request_user);

    const whoami = getCurrentLoggedInUser()

    const status = caller_request.caller_request_status;

    const colorFromInputStatus = (inputStatus: eInputStatus): string => {
        switch (inputStatus) {
            case eInputStatus.notChanged:
                return 'inherit';
            case eInputStatus.waiting:
                return 'orange';
            case eInputStatus.success:
                return 'green';
            case eInputStatus.failure:
                return 'red';
        }
    }

    return <tr>
        <td>
            <ul className="d-flex align-items-center">
                <li className="list-inline-item">
                    <img alt="Avatar" className="table-avatar" src={user?.user_profile_pic ?? defaultAvatar}/>
                </li>

                <br/>

                <li className="list-inline-item ml-2">
                    <Link to={`/profile/${user?.user_id}`} style={{cursor: 'pointer'}}>
                        {user?.user_username}
                    </Link>
                </li>
            </ul>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <div className="icheck-primary d-inline">
                    <input type="radio" id="radioPrimary2"
                           onClick={() => Users.Put({
                               user_is_caller: 0,
                               user_id: user?.user_id,
                           })?.then(response => {
                               console.log('response null caller', response)
                               Caller_Requests.Put({
                                   ...caller_request,
                                   caller_request_status: null,
                                   caller_request_completed_by: whoami?.user_id
                               })
                           })
                           } checked={null === status} name="r1"/>
                    <label htmlFor="radioPrimary2"></label>
                </div>
                <div className="icheck-success d-inline">
                    <input type="radio" name="r3"
                           onClick={() => Users.Put({
                               user_is_caller: 1,
                               user_id: user?.user_id,
                           })?.then(response => {
                               console.log('response accept caller', response)
                               Caller_Requests.Put({
                                   ...caller_request,
                                   caller_request_status: 1,
                                   caller_request_completed_by: whoami?.user_id
                               })
                           })} checked={1 === status} id="radioSuccess1"/>
                    <label htmlFor="radioSuccess1"></label>
                </div>
                <div className="icheck-danger d-inline">
                    <input type="radio" name="r2" onClick={() => Users.Put({
                        user_is_caller: 0,
                        user_id: user?.user_id,
                    })?.then(response => {
                        console.log('response deny caller', response)
                        Caller_Requests.Put({
                            ...caller_request,
                            caller_request_status: 0,
                            caller_request_completed_by: whoami?.user_id
                        })
                    })} checked={0 === status} id="radioDanger3"/>
                    <label htmlFor="radioDanger3"></label>
                </div>
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <ul className="d-flex align-items-center">
                    <li className="list-inline-item">
                        <img alt="Avatar" className="table-avatar" src={user?.user_profile_pic ?? defaultAvatar}/>
                    </li>

                    <br/>

                    <li className="list-inline-item ml-2">
                        <Link to={`/profile/${user?.user_id}`} style={{cursor: 'pointer'}}>
                            {user?.user_username}
                        </Link>
                    </li>
                </ul>
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                {caller_request?.caller_request_message ?? 'No message provided'}
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <div className="input-group mb-3">
                    <input type="text" className="form-control"
                           value={callerRequestResponse ?? undefined} onChange={(event) => {

                        setCallerRequestResponse(event.target.value);

                        if (event.target.value === caller_request.caller_request_response) {

                            return;

                        }

                        setInputStatus(eInputStatus.waiting);

                        watchInputElementChangeEvent(event, async (): Promise<boolean> => {

                            return (await Caller_Requests.Put({
                                ...caller_request,
                                caller_request_response: event.target.value,
                                caller_request_completed_by: whoami?.user_id
                            })?.then(() => {
                                setInputStatus(eInputStatus.success)
                                return true
                            }).catch(() => {
                                setInputStatus(eInputStatus.failure);
                                return false;
                            }) ?? false);
                        });

                    }}/>
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i style={{color: colorFromInputStatus(inputStatus)}} className="fas fa-check"></i>
                        </span>
                    </div>
                </div>
            </div>
        </td>


    </tr>
}