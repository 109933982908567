import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import uploadImage from "api/hoc/uploadImage";
import {iUsers} from "api/rest/C6";
import Users from "api/rest/Users";
import Voltxt from "Voltxt";


// we're running uploadImage()
export default uploadImage(base64 => {

    console.log(base64)

    const whoami = getCurrentLoggedInUser()

    const updateUser: iUsers = {
        user_id: whoami?.user_id,
        user_profile_pic: base64
    }

    Users.Put(updateUser)?.then((returnDate) => {

        console.log(returnDate)

        Voltxt.instance.updateRestfulObjectArrays<iUsers>({
            dataOrCallback: [
                updateUser
            ],
            stateKey: "users",
            uniqueObjectId: "user_id"
        });

    })

})