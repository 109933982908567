import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import uploadImage from "api/hoc/uploadImage";
import {iPhotos} from "api/rest/C6";
import Photos from "api/rest/Photos";
import Voltxt from "Voltxt";


const newImage = ({description, parent_id}: { description: string, parent_id: string }) => uploadImage(base64 => {

    console.log('description', description, base64)

    const whoami = getCurrentLoggedInUser()

    const photo: iPhotos = {
        user_id: whoami?.user_id,
        parent_id: parent_id,
        photo_src: base64,
        photo_description: description
    }

    Photos.Post(photo)?.then((returnDate) => {

        console.log(returnDate)

        Voltxt.instance.updateRestfulObjectArrays<iPhotos>({
            dataOrCallback: [
                photo
            ],
            stateKey: "photos",
            uniqueObjectId: "photo_id"
        });

    })

})

export default newImage;