import {PropsWithChildren} from "react";
import { Link } from 'react-router-dom';

const ContentHeader = ({title, children = undefined, showBreadcrumb = true, style}: PropsWithChildren<{title: string, showBreadcrumb?: boolean, style?}>) => {
  return (
    <section className="content-header" style={style}>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>{title} {children}</h1>

          </div>
          <div className="col-sm-6">
            {showBreadcrumb && <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                <Link to="/about">Home</Link>
              </li>
              <li className="breadcrumb-item active">{title}</li>
            </ol>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentHeader;
