import {faBitcoinSign, faEuro} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import getStyles from "api/hoc/getStyles";
import {convertDateToMySqlFormat, momentGeneralStringFormatForMysql} from "api/hoc/time";
import Projects from "api/rest/Projects";
import classNames from "classnames";
import moment, {Moment} from "moment/moment";
import Project from "pages/projects/Project";
import {useState} from "react";
import Datetime from "react-datetime";


export default function NewProject() {
    const whoami = getCurrentLoggedInUser();
    const style = getStyles();
    const today = moment();
    const [startDate, setStartDate] = useState<string>(today.toString())
    const [endDate, setEndDate] = useState<string>(today.toString())
    const [projectName, setProjectName] = useState<string>()
    const [projectDescription, setProjectDescription] = useState<string>()
    const [projectCost, setProjectCost] = useState<string>('0')
    const [projectOverviewTop, setProjectOverviewTop] = useState<string>()
    const [projectOverviewBottom, setProjectOverviewBottom] = useState<string>()


    return <div className="card card-primary">
        <div className="card-header">
            <h3 className="card-title">Quick Add</h3>
        </div>

        <div className={classNames(style.row, style.m5)}>
            <div className={style.col6}>
                <form>
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Project Name</label>
                            <input type="email" className="form-control" id="exampleInputEmail1"
                                   value={projectName} onChange={(e) => setProjectName(e.target.value)}
                                   placeholder="Enter the new project name"/>
                        </div>
                        <div className={style.row}>
                            <div className={style.col6}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputFile">Start Date</label>
                                    <div className="input-group" style={{color: "black"}}>
                                        <Datetime
                                            className={"w-100"}
                                            isValidDate={current => current.isAfter(today)}
                                            dateFormat={momentGeneralStringFormatForMysql}
                                            initialValue={moment(startDate).toDate()}
                                            initialViewDate={moment(startDate).toDate()}
                                            onChange={(value: Moment | string) => setStartDate('string' === typeof value ? value : value.toString())}
                                            timeConstraints={
                                                {
                                                    hours: {min: 0, max: 1, step: 1}
                                                }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.col6}>
                                <div className="form-group">
                                    <label htmlFor="endDate">End Date</label>
                                    <div className="input-group" style={{color: "black"}}>
                                        <Datetime
                                            className={"w-100"}
                                            isValidDate={current => current.isAfter(today) && current.isAfter(startDate)}
                                            dateFormat={momentGeneralStringFormatForMysql}
                                            initialValue={moment(endDate).toDate()}
                                            initialViewDate={moment(endDate).toDate()}
                                            onChange={(value: Moment | string) => setEndDate('string' === typeof value ? value : value.toString())}
                                            timeConstraints={
                                                {
                                                    hours: {min: 0, max: 1, step: 1}
                                                }
                                            }/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="projectDescription">Project Description</label>
                            <div className="form-group">
                                        <textarea id={'projectDescription'} className="form-control" rows={3}
                                                  value={projectDescription}
                                                  onChange={(event) => setProjectDescription(event.target.value)}
                                                  placeholder="Who is your content for ... What will you be posting ... When will you be posting ... Where is your favorite place to visit?"></textarea>
                            </div>
                        </div>


                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Monthly Cost (USD)</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="fas fa-dollar-sign"></i>
                                    </div>
                                </div>
                                <input type="number" className="form-control" value={projectCost}
                                       onChange={(event) => setProjectCost(event.target.value)}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBitcoinSign} size={'lg'}/>
                                    </div>
                                </div>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faEuro} size={'lg'}/>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Project Overview Top</label>
                            <div className="form-group">
                                <input id={'projectOverviewTop'} className="form-control"
                                       value={projectOverviewTop}
                                       onChange={(event) => setProjectOverviewTop(event.target.value)}
                                       placeholder="Estimated monthly return"></input>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Project Overview Bottom</label>
                            <div className="form-group">
                                <input id={'projectOverviewBottom'} className="form-control"
                                       value={projectOverviewBottom}
                                       onChange={(event) => setProjectOverviewBottom(event.target.value)}
                                       placeholder="122%"></input>
                            </div>
                        </div>

                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                            <label className="form-check-label" htmlFor="exampleCheck1">Check me
                                out</label>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()

                            Projects.Post({
                                project_name: projectName,
                                project_description: projectDescription,
                                project_start_date: convertDateToMySqlFormat(startDate),
                                project_end_date: convertDateToMySqlFormat(endDate),
                                project_leader: whoami?.user_id,
                                project_cost: parseFloat(projectCost),
                                project_overview_top: projectOverviewTop,
                                project_overview_bottom: projectOverviewBottom
                            })

                        }}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <div className={classNames(style.col6)}>
                <Project project={{
                    project_leader: whoami?.user_id,
                    project_name: projectName,
                    project_description: projectDescription,
                    project_cost: parseFloat(projectCost),
                    project_start_date: convertDateToMySqlFormat(startDate),
                    project_end_date: convertDateToMySqlFormat(endDate),
                    project_overview_top: projectOverviewTop,
                    project_overview_bottom: projectOverviewBottom,
                }} blur/>
            </div>
        </div>


    </div>
}