import {iC6RestfulModel} from "@carbonorm/carbonnode";
import {watchInputElementChangeEvent} from "@carbonorm/carbonreact";
import Voltxt from "Voltxt";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import {iFlagged_Entities, iUsers, TABLES} from "api/rest/C6";
import Carbons from "api/rest/Carbons";
import Flagged_Entities from "api/rest/Flagged_Entities";
import Users from "api/rest/Users";
import defaultAvatar from "assets/img/defaultAvatar.svg";
import {Link} from "react-router-dom";


export default function FlaggedEntitiesListItem({flagged_entities, key}: { flagged_entities: iFlagged_Entities, key: number }) {

    const {users, carbons} = Voltxt.instance.state;

    const flaggedByUser = users?.find(users => users.user_id === flagged_entities.flagged_entity_report_from)

    const flaggedUser = users?.find(users => users.user_id === flagged_entities.flagged_entity_report_user)

    const respondedByUser = users?.find(users => users.user_id === flagged_entities.flagged_entity_responded_by)

    const whoami = getCurrentLoggedInUser();

    const tableData: iC6RestfulModel = JSON.parse(flagged_entities.flagged_entity_table_json ?? '{}')

    if (null !== flagged_entities.flagged_entity_id) {
        Carbons.Get({
            entity_pk: flagged_entities.flagged_entity_id,
        })
    }


    const convertEntityIdToLink = (entity_id: string = '') => {
        const projects = TABLES.projects.TABLE_NAME
        switch (tableData?.TABLE_NAME) {
            case projects:
                return <Link to={`/project/${entity_id}/`}>{entity_id}</Link>
            default:
                return entity_id
        }
    }


    const displayProfilePic = (user: iUsers) => <ul className="d-flex align-items-center">
        <li className="list-inline-item">
            <img alt="Avatar" className="table-avatar" src={user?.user_profile_pic ?? defaultAvatar}/>
        </li>

        <br/>

        <li className="list-inline-item ml-2">
            <Link to={`/profile/${user?.user_id}`} style={{cursor: 'pointer'}}>
                {user?.user_username}
            </Link>
        </li>
    </ul>

    return <tr key={key}>
        <td className={'List-inline'}>
            <div className="form-group">
                {flagged_entities.flagged_entity_report_id}
            </div>
        </td>
        <td>
            {flaggedByUser ? displayProfilePic(flaggedByUser) : flagged_entities.flagged_entity_responded_by}
        </td>

        <td className={'List-inline'}>
            {flaggedUser ? displayProfilePic(flaggedUser) : flagged_entities.flagged_entity_report_user}
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <div
                    className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input type="checkbox" className="custom-control-input" id={"disabledCustomSwitch" + key}
                           onClick={() => {
                               Users.Put({
                                   ...flaggedUser,
                                   user_is_disabled: flaggedUser?.user_is_disabled ? 0 : 1,
                               })
                           }}
                           checked={!!flaggedUser?.user_is_disabled}/>
                    <label className="custom-control-label" htmlFor={"disabledCustomSwitch" + key}/>
                </div>
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                {flagged_entities.flagged_entity_id && convertEntityIdToLink(flagged_entities.flagged_entity_id)}
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                    <input type="checkbox" className="custom-control-input" id={"deletedCustomSwitch" + key}
                           onClick={() => {

                               if (null === flagged_entities.flagged_entity_id) {

                                   return;

                               }

                               Carbons.Delete({
                                   entity_pk: flagged_entities.flagged_entity_id,
                               })

                           }}
                           checked={undefined === carbons?.find(carbons => carbons.entity_pk === flagged_entities.flagged_entity_id)}/>
                    <label className="custom-control-label" htmlFor={"deletedCustomSwitch" + key}/>
                </div>
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <textarea className="form-control" disabled={true} rows={3}
                          placeholder={JSON.stringify(JSON.parse(flagged_entities.flagged_entity_json ?? '{}'), undefined, 2)}/>
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <textarea className="form-control" disabled={true} rows={3}
                          placeholder={JSON.stringify(tableData, undefined, 2)}/>
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                {flagged_entities.flagged_entity_report_created}
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                {flagged_entities.flagged_entity_report_message}
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                <img alt={'flagged proof'} src={flagged_entities.flagged_entity_image ?? ''} height={'100px'}/>
            </div>
        </td>
        <td className={'List-inline'}>
            <div className="form-group">
                 <textarea className="form-control" rows={3}
                           onChange={(event) => {
                               watchInputElementChangeEvent(event, async (event) => {
                                   const promise = Flagged_Entities.Put({
                                       ...flagged_entities,
                                       flagged_entity_response_message: event.target.value,
                                       flagged_entity_responded_by: whoami?.user_id,
                                   });
                                   return 'function' === typeof promise
                                       ? true
                                       : await promise?.then(_response => {
                                       return true
                                   }).catch(_error => {
                                       return false
                                   }) ?? false
                               })
                           }}
                           placeholder={flagged_entities.flagged_entity_response_message ?? 'N/A'}/>

            </div>
        </td>
        <td className={'List-inline'}>
            {respondedByUser ? displayProfilePic(respondedByUser) : flagged_entities.flagged_entity_responded_by ?? 'N/A'}
        </td>

    </tr>
}