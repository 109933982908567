import Voltxt from "Voltxt";
import getCurrentLoggedInUser from "api/hoc/getCurrentLoggedInUser";
import { useNavigate, Link } from 'react-router-dom';
import logo from 'assets/img/voltexVFullColor.svg';
import styles from 'style.module.scss';
import { axiosInstance } from "@carbonorm/carbonnode";

const Header = () => {
    console.log('Header TSX rendered');

    const navigate = useNavigate();

    const logUserOut = async (event: any) => {
        event.preventDefault();

        let promise = await axiosInstance.get('/bootstrap/logout/');

        console.log('promise', promise);

        Voltxt.instance.setState({
            user_id: '',
        });

        navigate('/about');
    };

    const whoami = getCurrentLoggedInUser();
    const loggedOut = whoami === undefined;

    let dynamicMenuItems: any[] = [];
    let signOut: any[] = [];

// {    const handleWalletClick = (event: any) => {
//         event.preventDefault();
//         setShowWalletCard(true);
//     };}

    if (loggedOut) {
        dynamicMenuItems = [
            <li className="nav-item">
                <Link className={styles.navLink} to={'/login'}>Login</Link>
            </li>,
            <li className="nav-item">
                <Link className={styles.navLink} to={"/register"}>Register</Link>
            </li>,
        ];
    } else {
        dynamicMenuItems = [
            <li className="nav-item">
                <Link className={styles.navLink} to="/dashboard">User Portal</Link>
            </li>,
            <li className="nav-item">
                <Link className={styles.navLink} to="/profile">Profile</Link>
            </li>,
            <li className="nav-item">
                <Link className={styles.navLink} to="/apply">Apply</Link>
            </li>,
            <li className="nav-item">
                <Link className={styles.navLink} to="/buy">Buy</Link>
            </li>,
            <li className="nav-item" key="wallet">
                <Link className={styles.navLink} to="/wallet" /*onClick={handleWalletClick}*/>Solana Wallet</Link>
            </li>,
        ];
        signOut = [
            <li className="nav-item">
                <Link className={styles.navLink} to="/about" onClick={logUserOut}>Sign Out</Link>
            </li>,
        ];
    }

    return (
        <nav className={styles.navbar}>
            {/* Logo on the left */}
            <Link to="/" className={styles.navbarBrand}>
                <img src={logo} alt="Logo" className={styles.logo}/>
            </Link>

            {/* Navigation Links on the right */}
            <div className={styles.navbarMenu}>
                <li className="nav-item">
                    <Link className={styles.navLink}
                          to="/home">Home</Link> {/** Temp at About Us until implementing Home Page */}
                </li>
                <li className="nav-item">
                    <Link className={styles.navLink} to="/about">About Us</Link>
                </li>
                {dynamicMenuItems}
                <li className="nav-item">
                    <Link className={styles.navLink} to="/support">Support</Link>
                </li>
                {signOut}
            </div>

            {/* Bottom bar */}
            <div className={styles.navbarBottomBar}></div>
        </nav>
    );
};

export default Header;
