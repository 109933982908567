import {Loading} from "@carbonorm/carbonreact";
import getStyles from "api/hoc/getStyles";
import {WithRouter} from "api/hoc/passPropertiesAndRender";
import {convertUTCToLocal} from "api/hoc/time";
import {iProjects, iSubscription, iUsers, TABLES} from "api/rest/C6";
import Projects from "api/rest/Projects";
import Subscription from "api/rest/Subscription";
import defaultAvatar from "assets/img/defaultAvatar.svg";
import Voltxt from "Voltxt";
import classNames from "classnames";
import FlagEntity from "components/FlagEntity/FlagEntity";
import Popup from "components/Popup/Popup";
import moment from "moment/moment";
import Post from "pages/profile/Post";
import {PropsWithChildren, useState} from "react";
import {Link} from "react-router-dom";


export function ProjectRoute({params}: WithRouter) {

    const project = Voltxt.instance.state.projects?.find((project) => project.project_id === params.project_id)

    // todo - get project if not in cache and auth subscription on backend
    if (undefined === project) {
        return <Loading message={'Project not yet loaded!'}/>
    }

    return <Project project={project}/>

}


export default function Project({project, blur = false}: PropsWithChildren<{
    project: iProjects, blur?: boolean
}>) {

    const [confirmDeletePopupOpen, setConfirmDeletePopupOpen] = useState(false);
    const [reportPopupOpen, setReportPopupOpen] = useState(false);

    const styles = getStyles();

    // get user object of the author
    const author: iUsers = Voltxt.instance.state.users?.find((user: iUsers) => user.user_id === project?.project_leader) ?? {}

    const posts = Voltxt.instance.state.posts?.filter((post) => post.post_project_id === project?.project_id) ?? []

    const subscription = Voltxt.instance.state.subscription

    const whoami = Voltxt.instance.state.user_id
    const openCloseModal = () => setConfirmDeletePopupOpen(!confirmDeletePopupOpen)

    const deleteProject = () => Projects.Delete(project)

    let time = moment().unix()

    let timeStart = moment(project?.project_start_date).unix() - time

    let timeEnd = moment(project?.project_end_date).unix() - time

    let percentComplete = 0

    console.log('timeStart', timeStart, 'timeEnd', timeEnd, 'percentComplete', percentComplete)

    if (0 > timeEnd) {

        percentComplete = 100

    } else if (0 < timeStart) {

        percentComplete = 0

    } else {

        let timeElapsed = Math.abs(timeStart),
            totalTime = timeEnd + timeElapsed;


        if (0 !== totalTime) {

            percentComplete = Math.round(timeElapsed / (timeEnd + timeElapsed) * 100)

        }

    }

    let testDataSubscription: iSubscription = subscription?.find((subscription) => subscription.subscription_for_entity_id === whoami && subscription.subscription_to_entity_id === project?.project_id) ?? {
        subscription_for_entity_id: whoami,
        subscription_to_entity_id: project?.project_id,
    }

    const subscribe = () => Subscription.Post(testDataSubscription)

    const unsubscribe = () => Subscription.Delete(testDataSubscription)

    const imSubscribed = Voltxt.instance.state.subscription?.find((subscription) =>
        subscription.subscription_to_entity_id === project?.project_id
        && subscription.subscription_for_entity_id === Voltxt.instance.state.user_id)

    console.log('percentComplete', percentComplete)


    return <section className="content">
        <Popup open={confirmDeletePopupOpen} maxWidth={'800px'} handleClose={openCloseModal}>
            <div className={classNames(styles.card, styles.cardDanger)}>
                <div className="card-header">
                    <h3 className="card-title">Delete Project</h3>
                    <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-widget="collapse"
                            title="Collapse"
                            data-toggle="collapse"
                            aria-expanded="true"
                            data-target=".card-body"
                            aria-controls="collapseExample"
                        >
                            <i className="fa fa-minus"/>
                        </button>
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-widget="remove"
                            data-toggle="tooltip"
                            title="Remove"
                        >
                            <i className="fa fa-times"/>
                        </button>
                    </div>
                </div>
                <div className="card-body" id={'collapseExample'}>
                    This will cancel the project and delete all posts and comments.
                    All uses who are subscribed will have subscriptions canceled. Are you sure?
                </div>
                <div className="card-footer">

                    (18) users will be impacted.

                    <div className="btn-group float-right">
                        <button type="button" className="btn btn-secondary" onClick={openCloseModal}>Close</button>
                        <button type="button" className="btn btn-danger" onClick={deleteProject}>Confirm Delete</button>
                    </div>
                </div>
            </div>
        </Popup>
        <div className="card">
            <div className="card-header">
                <h3 className="card-title"> Detail</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="remove"
                            onClick={() => {
                                setConfirmDeletePopupOpen(true)
                            }}
                            title="Remove">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-text text-center text-muted">Subscription cost</span>
                                        <span
                                            className="info-box-number text-center text-muted mb-0">${project?.project_cost} a month</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span
                                            className="info-box-text text-center text-muted">Subscribers on track</span>
                                        <span
                                            className="info-box-number text-center text-muted mb-0">14</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span
                                            className="info-box-text text-center text-muted">{project?.project_overview_top}</span>
                                        <span
                                            className="info-box-number text-center text-muted mb-0">{project?.project_overview_bottom}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>Activity {blur && <b>(hidden for non-subscribers)</b>}</h4>
                                <div className={classNames({
                                    [styles.blur]: blur
                                })}>

                                    {blur ? <>
                                            <Post post={{
                                                post_author: author?.user_id,
                                                post_data: project?.project_description,
                                            }} disableComments/>
                                            <Post post={{
                                                post_author: author?.user_id,
                                                post_data: project?.project_description,
                                            }} disableComments/>
                                            <Post post={{
                                                post_author: author?.user_id,
                                                post_data: project?.project_description,
                                            }} disableComments/>
                                        </>
                                        : posts.map((post) => <Post post={post}/>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                        <h3 className="text-primary"><i
                            className="fas fa-bolt"></i> <Link
                            to={'/project/' + project?.project_id + '/'}>{project?.project_name}</Link></h3>
                        <p className="text-muted">{project?.project_description}</p>
                        <br/>
                        <div className="text-muted">
                            <p className="text-sm">Project Leader
                                <b className="d-block">{author?.user_username}</b>
                            </p>
                            <img
                                className={classNames(styles.imgCircle, styles.imgSm, styles.mr5)}

                                width={25}
                                height={25}
                                slot="button"
                                src={author?.user_profile_pic || defaultAvatar}
                                alt="User"
                            />
                            <p className="text-sm">Member since
                                <b className="d-block">{convertUTCToLocal(author?.user_creation_date)}</b>
                            </p>
                        </div>
                        <div className="project_progress">
                            <div className="progress progress-sm">
                                <div className="progress-bar bg-green" role="progressbar"
                                     aria-valuenow={percentComplete}
                                     aria-valuemin={0}
                                     aria-valuemax={100} style={{width: percentComplete + "%"}}>
                                </div>
                            </div>
                            <small>
                                {percentComplete}% Complete
                            </small>
                        </div>
                        <div className="text-muted">
                            <br/>
                            <p className="text-sm">Project Started On
                                <b className="d-block">{convertUTCToLocal(project?.project_start_date)}</b>
                            </p>
                            <p className="text-sm">End Date
                                <b className="d-block">{convertUTCToLocal(project?.project_end_date)}</b>
                            </p>
                        </div>
                        <h5 className={classNames(styles.mt5, styles.textMuted)}>Project photos</h5>
                        <ul className={classNames(styles.listUnstyled, {
                            [styles.blur]: blur
                        })}>
                            <li>
                                <a href="" className="btn-link text-secondary"><i
                                    className="far fa-fw fa-image "></i> Logo.png</a>
                            </li>
                            {/*<li>
                                <a href="" className="btn-link text-secondary"><i
                                    className="far fa-fw fa-file-word"></i> Contract-10_12_2014.docx</a>
                            </li>*/}
                        </ul>
                        <div className="text-center mt-5 mb-3">
                            {imSubscribed
                                ? <a href="#" className="btn btn-sm btn-light" onClick={unsubscribe}>Unsubscribe</a>
                                : <a href="#" className="btn btn-sm btn-success" onClick={subscribe}>Subscribe</a>}
                            {' '}
                            {project?.project_id &&
                                <div onClick={() => setReportPopupOpen(true)} className="btn btn-sm btn-danger">Report
                                    project</div>}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <FlagEntity open={reportPopupOpen}
                    handleClose={() => setReportPopupOpen(false)}
                    tableData={TABLES.projects}
                    entity={project}
                    entity_owner_id={project?.project_leader ?? 'ERROR'}
                    entity_id={project?.project_id ?? 'ERROR'}/>

    </section>;
};
