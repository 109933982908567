import {faReply} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getStyles from "api/hoc/getStyles";
import {convertUTCToLocal} from "api/hoc/time";
import {iPosts, iUsers} from "api/rest/C6";
import Posts from "api/rest/Posts";
import Voltxt from "Voltxt";
import classNames from "classnames";
import Popup from "components/Popup/Popup";
import {useState} from "react";
import {Link} from 'react-router-dom';
import defaultAvatar from 'assets/img/defaultAvatar.svg';
import MarkdownEditor from '@uiw/react-markdown-editor';


// must be named for recursion to work
export default function Post({isClearfix = false, post, disableComments = false}: {
    isClearfix?: boolean,
    post: iPosts,
    disableComments?: boolean
}) {
    const [showComments, setShowComments] = useState(false);
    const [showReplyBox, setShowReplyBox] = useState(false);
    const [replyText, setReplyText] = useState('');
    const [confirmDeletePostId, setConfirmDeletePostId] = useState<string | undefined>(undefined);

    const closeDeletePopup = () => setConfirmDeletePostId(undefined);

    const postAuthor = Voltxt.instance.state.users?.find((user) => user.user_id === post.post_author);

    const project = Voltxt.instance.state.projects?.find((project) => project.project_id === post.post_project_id);

    const styles = getStyles();

    const postReply = () => Posts.Post({
        post_author: Voltxt.instance.state.user_id,
        post_data: replyText,
        post_parent_id: post.post_id,
    })

    const immediateReplies = Voltxt.instance.state.posts?.filter((reply) => reply.post_parent_id === post.post_id);

    let parentPostAuthor: iUsers | undefined = undefined;

    if (undefined !== post.post_parent_id) {

        // get author of parent post
        const parentPost = Voltxt.instance.state.posts?.find((parentPost) => parentPost.post_id === post.post_parent_id);

        parentPostAuthor = Voltxt.instance.state.users?.find((user) => user.user_id === parentPost?.post_author);

    }

    return <>
        <Popup open={undefined !== confirmDeletePostId} maxWidth={'800px'} handleClose={closeDeletePopup}>
            <div className={classNames(styles.card, styles.cardDanger)}>
                <div className="card-header">
                    <h3 className="card-title">Delete Post</h3>
                    <div className="card-tools">
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-widget="collapse"
                            title="Collapse"
                            data-toggle="collapse"
                            aria-expanded="true"
                            data-target=".card-body"
                            aria-controls="collapseExample"
                            onClick={closeDeletePopup}
                        >
                            <i className="fa fa-minus"/>
                        </button>
                        <button
                            type="button"
                            className="btn btn-tool"
                            data-widget="remove"
                            data-toggle="tooltip"
                            title="Remove"
                            onClick={closeDeletePopup}
                        >
                            <i className="fa fa-times"/>
                        </button>
                    </div>
                </div>
                <div className="card-body" id={'collapseExample'}>
                    This will remove the post from the database. This action cannot be undone.
                </div>
                <div className="card-footer">

                    (18) users will be impacted.

                    <div className="btn-group float-right">
                        <button type="button" className="btn btn-secondary" onClick={closeDeletePopup}>Close</button>
                        <button type="button" className="btn btn-danger" onClick={() => {
                            closeDeletePopup()
                            Posts.Delete({
                                post_id: confirmDeletePostId
                            })
                        }}>Confirm Delete
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
        <div className={`post ${isClearfix ? 'clearfix' : ''}`}>
            <div className="user-block">
                <img
                    className="img-circle elevation-2"
                    src={postAuthor?.user_profile_pic ?? defaultAvatar}
                    alt="User"
                    width={40}
                    height={40}
                />
                <div className="username">
                    <Link to="/">{postAuthor?.user_username}</Link>
                    <div className="float-right btn-tool">
                        <i className="fas fa-times"
                           onClick={() => {
                               if (undefined === post.post_id) {
                                   console.log('post.post_id is undefined')
                               }
                               setConfirmDeletePostId(post?.post_id)
                           }}/>
                    </div>
                </div>
                <div className="description">
                    {post?.post_parent_id
                        ? <>Reply to {parentPostAuthor?.user_username}</>
                        : <>Shared privately to <Link
                            to={'/project/' + project?.project_id + '/'}>{project?.project_name}</Link></>}
                    {' - '}{post?.post_created_datetime ? convertUTCToLocal(post.post_created_datetime) : 'just now'}
                </div>

            </div>
            {/* /.user-block */}

            <MarkdownEditor.Markdown
                source={post?.post_data ?? ''}
                className={classNames(styles.m2)}
                style={{backgroundColor: 'transparent'}}/>

            <div className={classNames(styles.mb2, styles.mt3)}>
                <div className={'row'}>
                    <div className="col link-black text-sm">
                        <i className="far fa-thumbs-up mr-1"/>
                        Like
                    </div>
                    <div onClick={() => setShowComments(!showComments)} className="col link-black text-sm">
                        <i className="far fa-comments mr-1"/>
                        View Comments ({immediateReplies?.length ?? 0})
                    </div>
                    <div onClick={() => setShowReplyBox(!showReplyBox)} className="col link-black text-sm">
                        <FontAwesomeIcon icon={faReply} className="mr-1"/>
                        reply
                    </div>
                </div>
            </div>

            {/* <input className="form-control form-control-sm" type="text" placeholder="Type a comment" /> */}
            {disableComments || (showReplyBox && <div className="form-horizontal">
                <div className="input-group input-group-sm mb-0">
                    <input
                        className="form-control form-control-sm"
                        placeholder="Response"
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                    />
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-success" onClick={postReply}>
                            Send
                        </button>
                    </div>
                </div>
            </div>)}
            {showComments && immediateReplies &&
                <div className={classNames('ml-5 mt-3')}>{immediateReplies.map((post) => <Post key={post.post_id}
                                                                                               post={post}/>)}</div>}
        </div>
    </>
}

