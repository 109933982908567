import {
    iPostC6RestResponse,
    restRequest,
    GET,
    POST,
    PUT,
    DELETE,
    iDeleteC6RestResponse,
    iGetC6RestResponse,
    iPutC6RestResponse,
    removeInvalidKeys,
    iAPI,
    Modify
} from "@carbonorm/carbonnode";
import {AxiosResponse} from "axios";
import {C6, caller_requests, iCaller_Requests, RestTableNames} from "./C6";
import Voltxt from "Voltxt";

/**
CREATE TABLE `carbon_caller_requests` (
  `caller_request_id` binary(16) NOT NULL,
  `caller_request_user` binary(16) NOT NULL,
  `caller_request_status` tinyint DEFAULT NULL,
  `caller_request_completed_by` binary(16) DEFAULT NULL,
  `caller_request_message` varchar(255) NOT NULL,
  `caller_request_response` varchar(255) DEFAULT NULL,
  PRIMARY KEY (`caller_request_id`),
  KEY `carbon_caller_requests___fk` (`caller_request_completed_by`),
  KEY `carbon_caller_requests_carbon_carbons_entity_pk_fk2` (`caller_request_user`),
  CONSTRAINT `carbon_caller_requests___fk` FOREIGN KEY (`caller_request_completed_by`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `carbon_caller_requests_carbon_carbons_entity_pk_fk` FOREIGN KEY (`caller_request_id`) REFERENCES `carbon_carbons` (`entity_pk`) ON DELETE CASCADE ON UPDATE CASCADE,
  CONSTRAINT `carbon_caller_requests_carbon_carbons_entity_pk_fk2` FOREIGN KEY (`caller_request_user`) REFERENCES `carbon_carbons` (`entity_pk`)
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci;
**/

type GetCustomAndRequiredFields = {}

type GetRequestTableOverrides = {}

// required parameters, optional parameters, parameter type overrides, response, and table names
const Get = restRequest<GetCustomAndRequiredFields, iCaller_Requests, GetRequestTableOverrides, iGetC6RestResponse<iCaller_Requests>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: caller_requests.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        request.success ??= 'Successfully received caller_requests!'
        request.error ??= 'An unknown issue occurred creating the caller_requests!'
        return request
    },
    responseCallback: (response, _request) => {
        const responseData = response?.data?.rest;
         Voltxt.instance.updateRestfulObjectArrays<iCaller_Requests>({
            dataOrCallback: Array.isArray(responseData) ? responseData : [responseData],
            stateKey: "caller_requests",
            uniqueObjectId: C6.caller_requests.PRIMARY_SHORT as (keyof iCaller_Requests)[]
        })
    }
});

type PutCustomAndRequiredFields = {}

type PutRequestTableOverrides = {}

export function putState(response: AxiosResponse<iPutC6RestResponse<iCaller_Requests>>, request: iAPI<Modify<iCaller_Requests, PutRequestTableOverrides>> & PutCustomAndRequiredFields) {
     Voltxt.instance.updateRestfulObjectArrays<iCaller_Requests>({
        dataOrCallback: [
            removeInvalidKeys<iCaller_Requests>({
                ...request,
                ...response?.data?.rest,
            }, C6.TABLES)
        ],
        stateKey: "caller_requests",
        uniqueObjectId: caller_requests.PRIMARY_SHORT as (keyof iCaller_Requests)[]
    })
}

const Put = restRequest<PutCustomAndRequiredFields, iCaller_Requests, PutRequestTableOverrides, iPutC6RestResponse<iCaller_Requests>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: caller_requests.TABLE_NAME,
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success ??= 'Successfully updated caller_requests data!'
        request.error ??= 'An unknown issue occurred updating the caller_requests data!'
        return request
    },
    responseCallback: putState
});

type PostCustomAndRequiredFields = {}

type PostRequestTableOverrides = {}

export function postState(response: AxiosResponse<iPostC6RestResponse<iCaller_Requests>>, request: iAPI<Modify<iCaller_Requests, PostRequestTableOverrides>> & PostCustomAndRequiredFields, id: string | number | boolean) {
    if ('number' === typeof id || 'string' === typeof id) {
        if (1 !== caller_requests.PRIMARY_SHORT.length) {
            console.error("C6 received unexpected result's given the primary key length");
        } else {
            request[caller_requests.PRIMARY_SHORT[0]] = id
        }
    }
     Voltxt.instance.updateRestfulObjectArrays<iCaller_Requests>({
        dataOrCallback: undefined !== request.dataInsertMultipleRows
            ? request.dataInsertMultipleRows.map((request, index) => {
                return removeInvalidKeys<iCaller_Requests>({
                    ...request,
                    ...(index === 0 ? response?.data?.rest : {}),
                }, C6.TABLES)
            })
            : [
                removeInvalidKeys<iCaller_Requests>({
                    ...request,
                    ...response?.data?.rest,
                }, C6.TABLES)
            ],
        stateKey: "caller_requests",
        uniqueObjectId: caller_requests.PRIMARY_SHORT as (keyof iCaller_Requests)[]
    })
}

const Post = restRequest<PostCustomAndRequiredFields, iCaller_Requests, PostRequestTableOverrides, iPostC6RestResponse<iCaller_Requests>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: caller_requests.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Successfully created the caller_requests data!'
        request.error ??= 'An unknown issue occurred creating the caller_requests data!'
        return request
    },
    responseCallback: postState
});

type DeleteCustomAndRequiredFields = {}

type DeleteRequestTableOverrides = {}

export function deleteState(_response: AxiosResponse<iDeleteC6RestResponse<iCaller_Requests>>, request: iAPI<Modify<iCaller_Requests, DeleteRequestTableOverrides>> & DeleteCustomAndRequiredFields) {
     Voltxt.instance.deleteRestfulObjectArrays<iCaller_Requests>({
        dataOrCallback: [
            request
        ],
        stateKey: "caller_requests",
        uniqueObjectId: caller_requests.PRIMARY_SHORT as (keyof iCaller_Requests)[]
    })
}

const Delete = restRequest<DeleteCustomAndRequiredFields, iCaller_Requests, DeleteRequestTableOverrides, iDeleteC6RestResponse<iCaller_Requests>, RestTableNames>({
    C6: C6,
    restURL: require('variables/backendURL.tsx').default + 'rest/',
    tableName: caller_requests.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success ??= 'Successfully removed the caller_requests data!'
        request.error ??= 'An unknown issue occurred removing the caller_requests data!'
        return request
    },
    responseCallback: deleteState
});

const Caller_Requests = {
    // Export all GET, POST, PUT, DELETE functions for each table
    Get,
    Post,
    Put,
    Delete,
}

export default Caller_Requests;
