import {axiosInstance, eFetchDependencies} from "@carbonorm/carbonnode";
import Caller_Requests from "api/rest/Caller_Requests";
import Subscription from "api/rest/Subscription";
import {
    CarbonReact,
    initialRequiredCarbonORMState,
} from "@carbonorm/carbonreact";
import axiosInterceptors from "api/hoc/axiosInterceptors";
import {C6, caller_requests, initialRestfulObjectsState, iUsers} from "api/rest/C6";
import Posts from "api/rest/Posts";
import restProjects from "api/rest/Projects";
import {initialUiState} from "state/ui";
import {initialWebsocketState} from "state/websocket";
import backendURL from "variables/backendURL";
import isLocal from "variables/isLocal";
import isTest from "variables/isTest";
import "react-datetime/css/react-datetime.css";
import {initialSearchState} from "state/search";
import {initialChatState} from "state/chat";
import Chat_Messages from "./api/rest/Chat_Messages";
import Flagged_Entities from "./api/rest/Flagged_Entities";

// Combine all initial states
export const initialCarbonORMState: typeof initialRestfulObjectsState
    & typeof initialRequiredCarbonORMState
    & typeof initialUiState
    & typeof initialWebsocketState
    & typeof initialSearchState
    & typeof initialChatState
    & { user_id?: string } = {
    ...initialRestfulObjectsState,
    ...initialRequiredCarbonORMState,
    ...initialUiState,
    ...initialWebsocketState,
    ...initialSearchState,
    ...initialChatState,
    user_id: undefined,
};


export default class Voltxt extends CarbonReact<{}, typeof initialCarbonORMState> {

    static instance: Voltxt;

    state = initialCarbonORMState;

    constructor(props: any) {
        super(props);
        Voltxt.instance = this;
        axiosInterceptors(axiosInstance);
    }


    componentDidMount() {
        console.log('CarbonORM componentDidMount', backendURL, isTest, isLocal);

        axiosInstance.get(backendURL + 'bootstrap/info')
            .then((authReturn) => {
                if (authReturn.status !== 200) {
                    return;
                }

                this.setState({
                    user_id: authReturn.data?.whoami?.user_id ?? '',
                });

                this.updateRestfulObjectArrays<iUsers>({
                    dataOrCallback: [authReturn.data.whoami],
                    stateKey: 'users',
                    uniqueObjectId: 'user_id'
                });
            })
            .catch((error) => {
                console.log('error', error);
                return Promise.reject(error);
            });

        restProjects.Get({
            fetchDependencies: eFetchDependencies.CHILDREN | eFetchDependencies.C6ENTITY
        });

        Subscription.Get({
            fetchDependencies: eFetchDependencies.CHILDREN | eFetchDependencies.C6ENTITY
        });

        Posts.Get({
            fetchDependencies: eFetchDependencies.CHILDREN | eFetchDependencies.C6ENTITY
        });

        Caller_Requests.Get({
            [C6.SELECT]: [
                caller_requests.CALLER_REQUEST_ID,
                caller_requests.CALLER_REQUEST_USER,
                caller_requests.CALLER_REQUEST_STATUS,
                caller_requests.CALLER_REQUEST_COMPLETED_BY,
                caller_requests.CALLER_REQUEST_MESSAGE,
                caller_requests.CALLER_REQUEST_RESPONSE
            ],
            [C6.WHERE]: {
                [caller_requests.CALLER_REQUEST_COMPLETED_BY]: null,
            },
            [C6.PAGINATION]: {
                [C6.LIMIT]: 100,
            }
        });

        Chat_Messages.Get({
            fetchDependencies: eFetchDependencies.CHILDREN | eFetchDependencies.C6ENTITY
        });

        Flagged_Entities.Get();
    }

}
